export const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true
    }
    return false
}

// Validate a password
export const validatePassword = (password) => {
    if (/[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password) && /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password))  {
        return true
    }
    return false
}

// Validate a password that includes between 8 and 15 characters
export const validatePasswordLength = (password) => {
    if (password.length < 8 || password.length > 15) {
        return false
    } else {
        return true
    }
}