import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { catalan_calendar } from '../../utils/editDatePicker'
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import Modal from '../../components/Modal/Modal'
import getAllElements from '../../services/elements/getAllElements'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import getAllUsers from '../../services/users/getAllUsers'
import getAllUpkeeps from '../../services/upkeeps/getAllUpkeeps'
import postInspection from '../../services/inspections/postInspections'

import styles from './NewInspection.module.css'

const NewInspection = () => {
    
    let navigate = useNavigate()

    const [ inspection, setInspection ] = useState({})
    const [ selectedOptions, setSelectedOptions ] = useState([])
    const [ elements, setElements ] = useState([])
    const [ elementsFiltered, setElementsFiltered ] = useState([])
    const [ headquarters, setHeadquarters ] = useState([])
    const [ users, setUsers ] = useState([])
    const [ upkeeps, setUpkeeps ] = useState([])
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)
    const [ values, setValues ] = useState([])
    const [ checkedState, setCheckedState ] = useState([])

    useEffect(() => {
        getAllElements()
        .then(elements => {
            setElements(elements)
        })
        getAllUsers()
        .then(users => {
            setUsers(users)
        })
        getAllUpkeeps()
        .then(upkeeps => {
            setUpkeeps(upkeeps)
            setCheckedState(new Array(upkeeps.length).fill(false))
        })
        getAllHeadquarters()
        .then(headquarters => {
            setHeadquarters(headquarters)
        })
    },[])

    const handleSubmit = (event) => {
        event.preventDefault()
        for (let index = 0; index < values.length; index++) {
            let form = event.target
            let formData = new FormData(form)
            let formJson = Object.fromEntries(formData.entries())
            let arraySelected = selectedOptions.map(element => {
                return {
                    "elementRegistersUpkeeps": element,
                    "elementValues": ""
                }
            })
            let dateUnix = new Date(values[index].unix * 1000).toISOString()
            formJson.elementPlanningDate = dateUnix
            formJson.elementRegisters = arraySelected
            console.log(formJson)
            postInspection(formJson) 
            .then(newInspection => {
                setInspection(newInspection)
            })
        }
        setDisable(false)
        setTimeout(() => {
            setDisable(true)
            navigate("/inspeccions", { replace: true })
        },3000)
    }

    const handleChange = (event) => {
        const { name, value } = event.target 
        setInspection ( ( prevFormData ) => ({ 
            ...prevFormData, 
            [name]: value, 
        }))
    }

    const handleOnChange = (event) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === parseInt(event.target.id) ? !item : item
        )
        const updatedOptionsRegister = upkeeps
        .map((element, index) => {
            if (updatedCheckedState[index] === true) {
                return element._id
            }
            return null
        })
        .filter(element => element !== null)
        setCheckedState(updatedCheckedState)
        setInspection(updatedOptionsRegister)
        setSelectedOptions(updatedOptionsRegister)
    }

    const handleSelectArea = (event) => {
        if (event.target.value !== "select") {
            let elementsFiltereds = elements.filter(element => event.target.value.includes(element.elementAreaId._id))       
            setElementsFiltered(elementsFiltereds)
        } else {
            setElementsFiltered([])
        }
    }

    return (
        <div className={styles.container}>
            <h2>Crear inspecció</h2>
            <div className={`${styles.modal} ${disable && styles.disable}`}>
                <Modal error={error} text={"Inspecció creada correctament"}></Modal>
            </div>
            <form onSubmit={handleSubmit}>
                <label for="areaId">Centres</label>
                <select onChange={handleSelectArea} name="areaId" required>
                    <option value="select">- Selecciona -</option>
                    {
                        headquarters && headquarters.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.areaName}</option>
                            )
                        })
                    }
                </select>
                <label for="elementId">Element</label>
                <select onChange={handleChange} name="elementId" required disabled={elementsFiltered.length === 0 && true}>
                    {
                        elementsFiltered && elementsFiltered.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.elementName}</option>
                            )
                        })
                    }
                </select>
                <label>Dates</label>
                <DatePicker   
                    style={{
                        width: "100%",
                        boxSizing: "border-box",
                        height: "100%",
                        padding: "0.5em",
                        backgroundColor: "aliceblue",
                        borderRadius: ".3rem",
                        border: "none",
                    }}
                    containerStyle={{
                        width: "100%"
                    }}
                    calendarPosition="bottom-center" 
                    locale={catalan_calendar}
                    displayWeekNumbers="enable"
                    numberOfMonths={3}
                    weekStartDayIndex={1}
                    multiple 
                    value={values} 
                    format="DD/MM/YYYY"
                    onChange={setValues} 
                    plugins={[<DatePanel />]}
                    disabled={elementsFiltered.length === 0 && true}
                />
                <label for="elementUserPlanningId">Usuari Planificat</label>
                <select onChange={handleChange} name="elementUserPlanningId" value={inspection.elementUserPlanningId} required disabled={elementsFiltered.length === 0 && true}>
                    {
                        users.map( element => {
                            return (
                                <option value={element._id}>{element.userName} {element.userLastName1} {element.userLastName2}</option>
                            )
                        })
                    }
                </select>
                <label for="elementRegisters">Manteniment</label>
                <ul className={styles.upkeeplist}>
                    {
                        upkeeps.map((element, index) => {
                            return (
                                <li key={index}>
                                <div className={styles.upkeeplistitem}>
                                    <div className={styles.leftsection}>
                                        <input
                                            type="checkbox"
                                            id={index}
                                            name={element.upkeepName}
                                            value={element._id}
                                            checked={checkedState[index]}
                                            onChange={handleOnChange}
                                            disabled={elementsFiltered.length === 0 && true}
                                        />
                                        <label htmlFor={`custom-checkbox-${index}`}>{element.upkeepName}</label>
                                    </div>
                                </div>
                                </li>
                            )
                        })
                    }
                </ul>
                <div className={styles.btn_container}>
                    <button className={ elementsFiltered.length === 0 ? styles.btn_add_disabled : styles.btn_add} type='submit'><i className="fa-solid fa-circle-plus"></i>Crear</button>
                </div>
            </form>
        </div>
    )
} 

export default NewInspection