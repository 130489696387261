import { NavLink } from 'react-router-dom'
import { LocalStorage } from '../../services/localStorage/LocalStorage.services'

import styles from './NavBar.module.css'

const NavBar = () => {
    return (
        LocalStorage.getItem("userProfile") !== "Administrador" ?
            <nav>
                <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/home">Home</NavLink>
                <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/inspeccions">Inspeccions</NavLink>
            </nav> :
            <nav>
                <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/home">Home</NavLink>
                <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/inspeccions">Inspeccions</NavLink>
                <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/accions">Accions</NavLink>
                <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/elements">Elements</NavLink>
                <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/empreses">Empreses</NavLink>
                <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/centres">Centres</NavLink>
                <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/manteniments">Controls</NavLink>
                <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/tipus-manteniment">Tipus</NavLink>
                <NavLink className={({isActive})=> isActive ? styles.active : styles.link} to="/usuaris">Usuaris</NavLink>
            </nav>
    )
}

export default NavBar
