import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import Modal from '../../components/Modal/Modal'
import getAllUpkeepTypes from '../../services/upkeepTypes/getAllUpkeepTypes'
import getAUpkeepById from '../../services/upkeeps/getUpkeepById'
import postUpkeep from '../../services/upkeeps/postUpkeep'
import putUpkeep from '../../services/upkeeps/putUpkeep'

import styles from './NewUpkeep.module.css'

const NewUpkeep = () => {

    const { id } = useParams()
    const [ newUpkeep, setNewUpkeep ] = useState({})
    const [ upkeepTypes, setUpkeepTypes ] = useState([])
    const [ active, setActive ] = useState(true)
    const [ valuesUp, setValuesUp ] = useState([])
    const [ valuesDown, setValuesDown ] = useState([])
    const [ type, setType ] = useState("")
    const [ limitType, setLimitType ] = useState("")
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)

    useEffect(() => {
        getAllUpkeepTypes()
        .then(upkeepTypes => {
            setUpkeepTypes(upkeepTypes)
        })
        if (id !== undefined) {
            getAUpkeepById(id)
            .then(upkeep => {
                setNewUpkeep(upkeep)
                setValuesUp(upkeep.upkeepLimitUp)
                setValuesDown(upkeep.upkeepLimitDown)
                setLimitType(upkeep.upkeepLimitType)
                setActive(upkeep.upkeepActive)
            })
        }
    },[id])

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        formData.append('upkeepActive', event.target.upkeepActive.checked)
        formData.append('upkeepLimitUp', valuesUp)
        formData.append('upkeepLimitDown', valuesDown)
        const formJson = Object.fromEntries(formData.entries())  
        if (id !== undefined) {
            putUpkeep(formJson, id) 
            .then(newUpkeep => {
                setNewUpkeep(newUpkeep);
                setDisable(false)
                setTimeout(() => {
                    setDisable(true)
                },3000)
            })
        } else {
            postUpkeep(formJson) 
            .then(newUpkeep => {
                setNewUpkeep(newUpkeep);
                setDisable(false)
                setTimeout(() => {
                    setDisable(true)
                },3000)
            })
        }
    }

    const handleChange = (event) => {
        if (event.target.id === "upkeepLimitUp") {
            setValuesUp(event.target.value)
        } else if (event.target.id === "upkeepLimitDown"){
            setValuesDown(event.target.value)
        } else if (event.target.id === "upkeepLimitType" ) {
            setLimitType(event.target.value)
        } else if (event.target.id === "upkeepActive") {
            setActive(event.target.checked)
        } else if (event.target.id === "upkeepValue") {
            setType(event.target.value)
        } else {
            setNewUpkeep(event.target.value)
        }
    }

    return (
        <div className={styles.container}>
            <div className={disable && styles.disable}>
                <Modal error={error} id={id} text={id ? "Manteniment modificat correctament" : "Manteniment creat correctament"}></Modal>
            </div>
            <h2>{id ? "Editar manteniment" : "Alta nou manteniment"}</h2>
            <form onSubmit={handleSubmit}>
                <label for="upkeepName">Manteniment</label>
                <input type='text' onChange={handleChange} name="upkeepName" value={newUpkeep.upkeepName}></input>
                <label for="upkeepDescription">Descripció</label>
                <textarea rows={3} type='text' onChange={handleChange} name="upkeepDescription" value={newUpkeep.upkeepDescription}></textarea>
                <label for="upkeepTypeId">Tipus manteniment</label>
                <select onChange={handleChange} name="upkeepTypeId" value={newUpkeep.upkeepTypeId} id="upkeepTypeId">
                    <option value="selecciona" selected disabled>- Selecciona -</option>
                    {
                        upkeepTypes.map( element => {
                            return (
                                <option value={element._id}>{element.upkeepTypeName}</option>
                            )
                        })
                    }
                </select>
                <label for="upkeepValue">Valor d'entrada</label>
                <select onChange={handleChange} name="upkeepValue" value={newUpkeep.upkeepValue} id="upkeepValue">
                    <option value="selecciona" selected disabled>- Selecciona -</option>
                    <option value="number">Numèric</option>
                    <option value="string">Text</option>
                    <option value="boolean">Si/No</option>
                </select>
                { type === "number" && <>
                        <label for="upkeepLimitType">Limits</label>
                        <select onChange={handleChange} name="upkeepLimitType" value={newUpkeep.upkeepLimitType} id="upkeepLimitType">
                            <option value="selecciona" selected disabled>- Selecciona -</option>
                            <option value="up">Límit Superior</option>
                            <option value="down">Límit Inferior</option>
                            <option value="up-down">Límits Superior i Límit Inferior</option>
                        </select>
                    </>
                }
                {
                    (type === "number" && limitType === "up") && <>
                        <label for="upkeepLimitUp">Limit Superior</label>
                        <input type='number' onChange={handleChange} name="upkeepLimitUp" value={newUpkeep.upkeepLimitUp} id="upkeepLimitUp" step="0.01" />
                    </>
                }
                {
                    (type === "number" && limitType === "down") && <>
                        <label for="upkeepLimitDown">Limit Inferior</label>
                        <input type='number' onChange={handleChange} name="upkeepLimitDown" value={newUpkeep.upkeepLimitDown} id="upkeepLimitDown" step="0.01" />
                    </>
                }
                {
                    (type === "number" && limitType === "up-down") && <>
                        <label for="upkeepLimitUp">Limit Superior</label>
                        <input type='number' onChange={handleChange} name="upkeepLimitUp" value={newUpkeep.upkeepLimitUp} id="upkeepLimitUp" step="0.01" />
                        <label for="upkeepLimitDown">Limit Inferior</label>
                        <input type='number' onChange={handleChange} name="upkeepLimitDown" value={newUpkeep.upkeepLimitDown} id="upkeepLimitDown" step="0.01" />
                    </>
                }
                {
                    type === "boolean" && <>
                        <label for="upkeepLimit">Limit</label>
                        <select onChange={handleChange} name="upkeepLimit" value={newUpkeep.upkeepLimit} id="upkeepLimit" >
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </>
                }
                {
                    id && <>
                        <label for="upkeepLimitUp">Limit Superior</label>
                        <input type='number' onChange={handleChange} name="upkeepLimitUp" value={parseFloat(valuesUp)} id="upkeepLimitUp"/>
                        <label for="upkeepLimitDown">Limit Inferior</label>
                        <input type='number' onChange={handleChange} name="upkeepLimitDown" value={parseFloat(valuesDown)} id="upkeepLimitDown"/>
                    </>
                }
                <label for="upkeepActive">Actiu</label>
                <input type='checkbox' onChange={handleChange} name="upkeepActive" id="upkeepActive" checked={active} className={styles.checking}/>
                <div className={styles.btn_container}>
                    <button className={styles.btn_add} type='submit'>{id ? <i className="fa-regular fa-floppy-disk"></i> : <i className="fa-solid fa-circle-plus"></i>}{id ? "Guardar" : "Crear"}</button>
                </div>
            </form>
        </div>
    )
}

export default NewUpkeep