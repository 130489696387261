import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import DatePicker from "react-multi-date-picker"
import Modal from '../Modal/Modal'
import { catalan_calendar } from '../../utils/editDatePicker'
import dayjs from 'dayjs'
import postInspection from '../../services/inspections/postInspections'

import styles from './ModalDuplicate.module.css'

const ModalLoggin = (props) => {

  let navigate = useNavigate()

  dayjs.locale('locale_es')
  dayjs().format('DD/MM/YYYY')

  const [ inspection, setInspection ] = useState({})
  const [ messageVisisble, setMessageVisible ] = useState(false)
  const [ error, setError ] = useState("")
  const [ text, setText ] = useState("")
  const [ initialDate, setInitialDate ] = useState(null)
  const [ finalDate, setFinalDate ] = useState(null)
  const [ checkedState, setCheckedState ] = useState([false,false,false,false,false,false,false])
  const [ selectedOptions, setSelectedOptions ] = useState([])
  const [ weeksDay, setWeeksDay ] = useState(    
    [
      {
        text: "Dilluns",
        value: 1
      }, 
      {
        text: "Dimarts",
        value: 2
      }, 
      {
        text: "Dimecres",
        value: 3
      }, 
      {
        text: "Dijous",
        value: 4
      }, 
      {
        text: "Divendres",
        value: 5
      }, 
      {
        text: "Dissabte",
        value: 6
      }, 
      {
        text: "Diumenge",
        value: 0
      },
    ])

  useEffect(() => {
    setInspection(props.inspection)
  },[props.inspection])

  const onChangeInitialDate = (date) => {
    setInitialDate(date)
  }

  const onChangeFinalDate = (date) => {
    if (initialDate === null) {
      setFinalDate(null)
    } else {
      setFinalDate(date)
    }
  }

  const handleOnChange = (event) => {
    const updatedCheckedState = checkedState.map((item, index) =>
        index === parseInt(event.target.id) ? !item : item
    )
    const updatedOptionsRegister = weeksDay
    .map((element, index) => {
        if (updatedCheckedState[index] === true) {
            return element.value
        }
        return null
    })
    .filter(element => element !== null)
    setCheckedState(updatedCheckedState)
    setSelectedOptions(updatedOptionsRegister)
  }
  
  const handleSubmit = (event) => {
    event.preventDefault()

    if (checkedState.includes(true) && initialDate !== null && finalDate !== null ) {
      const inici = new Date(initialDate)
      const fin = new Date(finalDate)
      const interval = 1000 * 60 * 60 * 24
      for (let i = inici; i <= fin; i = new Date(i.getTime() + interval)) {
        if (selectedOptions.includes(i.getDay())) {
          let formJson = {
            elementId: inspection.elementId._id,
            elementRegisters: inspection.elementRegisters,
            elementUserPlanningId: inspection.elementUserPlanningId._id
          }
          let dateUnix = new Date(i).toISOString()
          formJson.elementPlanningDate = dateUnix
          postInspection(formJson)
        }
      }
      setInspection({})
      setCheckedState([false,false,false,false,false,false,false])
      setSelectedOptions([])
      setInitialDate(null)
      setFinalDate(null)
      setText("Inspecció duplicada correctament")
      setMessageVisible(true)
      setTimeout(() => {
        setMessageVisible(false)
        props.setDisableDuplicate(true)
      },2000)
      setError("")
    } else {
      setError("Falta omplir algun dels camps")
      setMessageVisible(true)
      setTimeout(() => {
        setMessageVisible(false)
        navigate("/inspeccions", { replace: true })
      },2000)
    }

  }

  return (
    <div className={`${styles.modal}`}>
        <i className={"fa-regular fa-circle-xmark"} onClick={() => props.setDisableDuplicate(true)}></i>
        <form onSubmit={handleSubmit} className={styles.form}>
          <label for="initialDay">Data Inicial</label>
          <DatePicker   
            style={{
                width: "100%",
                boxSizing: "border-box",
                height: "100%",
                padding: "0.5em",
                backgroundColor: "aliceblue",
                borderRadius: ".3rem",
                border: "none",
            }}
            containerStyle={{
                width: "100%"
            }}
            calendarPosition="bottom-center" 
            locale={catalan_calendar}
            displayWeekNumbers="enable"
            numberOfMonths={1}
            weekStartDayIndex={1}
            value={initialDate} 
            format="DD/MM/YYYY"
            onChange={onChangeInitialDate}
            minDate={new Date(inspection.elementPlanningDate)}
            maxDate={new Date(inspection.elementPlanningDate).setDate(365)}
          />
          <label for="finalDay">Data Final</label>
          <DatePicker   
            style={{
                width: "100%",
                boxSizing: "border-box",
                height: "100%",
                padding: "0.5em",
                backgroundColor: "aliceblue",
                borderRadius: ".3rem",
                border: "none",
            }}
            containerStyle={{
                width: "100%"
            }}
            calendarPosition="bottom-center" 
            locale={catalan_calendar}
            displayWeekNumbers="enable"
            numberOfMonths={1}
            weekStartDayIndex={1}
            value={initialDate === null ? null : finalDate} 
            format="DD/MM/YYYY"
            onChange={onChangeFinalDate} 
            minDate={new Date(initialDate)}
            maxDate={new Date(initialDate).setDate(365)}
            disabled={initialDate === null && true}
          />
          <label for="weeksDay">Dia Setmana</label>
          <ul className={styles.datelist}>
              {
                weeksDay.map((element, index) => {
                  return (
                      <li key={index}>
                      <div className={styles.datelistitem}>
                          <div className={styles.leftsection}>
                              <input
                                  type="checkbox"
                                  id={index}
                                  name={element.text}
                                  value={element.value}
                                  checked={checkedState[index]}
                                  onChange={handleOnChange}
                              />
                              <label htmlFor={`custom-checkbox-${index}`}>{element.text}</label>
                          </div>
                      </div>
                      </li>
                  )
                })
              }
          </ul>
          <div className={styles.btn_container}>
              <button className={styles.btn_add} type='submit'>Guardar</button>
          </div>
        </form>
        <div className={`${!messageVisisble && styles.message_novisible }`}>
          <Modal error={error} text={text}></Modal>
        </div>
    </div>
  )
}

export default ModalLoggin
