import React, { useState, useEffect, useRef } from "react"
import { useReactToPrint } from 'react-to-print'
import { Badge, Calendar } from 'antd'
import dayjs from 'dayjs'
import locale_es from 'dayjs/locale/es'
import { Link } from 'react-router-dom'
import { LocalStorage } from '../../services/localStorage/LocalStorage.services'
import Filter from '../../components/Filter/Filter'
import Button from '../../components/UI/Button'
import getAllInspections from '../../services/inspections/getAllInspections'
import getAllElements from '../../services/elements/getAllElements'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import getAllCompanies from '../../services/companies/getAllCompanies'
import getAllUsers from '../../services/users/getAllUsers'

import styles from './CalendarInspections.module.css'

dayjs.locale(locale_es)
dayjs().format('DD/MM/YYYY')

const CalendarInspections = () => {

    const componentRef = useRef()

    const [ inspections, setInspections ] = useState([])
    const [ inspectionsFiltered, setInspectionsFiltered ] = useState([])
    const [ users, setUsers ] = useState([])
    const [ elements, setElements ] = useState([])
    const [ headquarters, setHeadquarters ] = useState([])
    const [ companies, setCompanies ] = useState([])

    const [ filterElements, setFilterElements ] = useState([])
    const [ filterUsers, setFilterUsers ] = useState([LocalStorage.getItem("id")])
    const [ filterCompany, setFilterCompany ] = useState([])
    const [ filterHeadquarters, setFilterHeadquarters ] = useState([])
    const [ filterActive, setFilterActive ] = useState([ false ])
    const [ filterValidate, setFilterValidate ] = useState([ true, false ])

    useEffect(() => {
        getAllInspections()
        .then(inspection => {
          setInspections(inspection)
          let inspectionsFilters = inspection.filter(element => {
            return element.elementPlanningActive === false && element.elementPlanningValidation === false && element.elementUserPlanningId._id === LocalStorage.getItem("id")  
          })
          setInspectionsFiltered(inspectionsFilters)
        })
        getAllCompanies()
        .then(companies => {
          setCompanies(companies)
          let arrayCompanies = companies.map(element => element._id)
          setFilterCompany(arrayCompanies)
          
        })
        getAllHeadquarters()
        .then(headquarters => {
          setHeadquarters(headquarters)
          let arrayArees = headquarters.map(element => element._id)
          setFilterHeadquarters(arrayArees)
        })
        getAllUsers()
        .then(users => {
          setUsers(users)
          let arrayUsers = users.map(element => element._id)
          setFilterUsers(arrayUsers)
        })
        getAllElements()
        .then(elements => {
          setElements(elements)
          let arrayElements = elements.map(element => element._id)
          setFilterElements(arrayElements)
        })
    },[])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Taula de inspeccions",
        onafterprint: () => alert('Print success')
    })

  // Función para hacer funcionar el filtro de elementos => por inspeccion
  const handleChangeInspection = (value) => {
    if (value.length !== 0) {
      setFilterElements(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId)  
      })
      setInspectionsFiltered(inspectionsFilters)
    } else {
      let allElements = elements.map(element => element._id)
      setFilterElements(allElements)
      let inspectionFiltered = inspections.filter(element =>  {
        return allElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId)
      }) 
      setInspectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de inspecciones
  const placeholderFilterInspections = "Filtre inspeccions"

  // Array con los elementos del filtro de inspecciones
  const dataInspections = elements.map(element => {
    return {
      label: `${element.elementName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por empresa
  const handleChangeCompany = (value) => {
    if (value.length !== 0) {
      setFilterCompany(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId)  
      })
      setInspectionsFiltered(inspectionsFilters)
    } else {
      let allCompanies = companies.map(element => element._id)
      setFilterCompany(allCompanies)
      let inspectionFiltered = inspections.filter(element =>  {
        return  allCompanies.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId)
      }) 
      setInspectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de empresa
  const placeholderFilterCompany = "Filtre empresa"

  // Array con los elementos del filtro de empresa
  const dataCompany = companies.map(element => {
    return {
      label: `${element.companyName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por Àrea
  const handleChangeHeadquarter = (value) => {
    if (value.length !== 0) {
      setFilterHeadquarters(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id)
      })
      setInspectionsFiltered(inspectionsFilters)
    } else {
      let allHeadquarters = headquarters.map(element => element._id)
      setFilterHeadquarters(allHeadquarters)
      let inspectionFiltered = inspections.filter(element =>  {
        return  allHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id)
      }) 
      setInspectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de areas
  const placeholderFilterHeadquarters = "Filtre centre"

  // Array con los elementos del filtro de areas
  const dataHeadquarters = headquarters.map(element => {
    return {
      label: `${element.areaName}`,
      value: element._id
    }
  })
  
  // Función para hacer funcionar el filtro de elementos => por usuario planificado
  const handleChangeUser = (value) => {
    
    if (value.length !== 0) {
      setFilterUsers(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation)
      })
      setInspectionsFiltered(inspectionsFilters)
    } else {
      let allUsers = users.map(element => element._id)
      setFilterUsers(allUsers)
      let inspectionFiltered = inspections.filter(element =>  {
        return  allUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation)
      }) 
      setInspectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de usuario planificado
  const placeholderFilterUser = "Filtre usuari planificat"

  // Array con los elementos del filtro de usuario planificado
  const dataUser = users.map(element => {
    return {
      label: `${element.userName} ${element.userLastName1} ${element.userLastName2}`,
      value: element._id
    }
  })

  const valueUser = LocalStorage.getItem("id")

    // Función para hacer funcionar el filtro de elementos => por estado
    const handleChangeValidate = (value) => {
      if (value.length !== 0) {
        setFilterValidate(value)
        let elementsFiltereds = inspections.filter(element => {
          return value.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive)
        })    
        setInspectionsFiltered(elementsFiltereds)
      } else {
        let allValidations = [ true, false ]
        setFilterValidate(allValidations)
        let inspectionFiltered = inspections.filter(element =>  {
          return allValidations.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive)
        }) 
        setInspectionsFiltered(inspectionFiltered)
      }
    }
  
    // Placeholder del filtro de estado
    const placeholderFilterValidate = "Filtre estat"
  
    // Array con los elementos del filtro de estado
    const dataValidate = [
      {
        label: "Pendent validar",
        value: false
      },
      {
        label: "Validada",
        value: true
      },
    ]
    
    // Función para hacer funcionar el filtro de elementos => por estado realizado
    const handleChangeActive = (value) => {
      if (value.length !== 0) {
        setFilterActive(value)
        let elementsFiltereds = inspections.filter(element => {
          return value.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id)
        })    
        setInspectionsFiltered(elementsFiltereds)
      } else {
        let allStates = [ true, false ]
        setFilterActive(allStates)
        let inspectionFiltered = inspections.filter(element =>  {
          return allStates.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id)
        }) 
        setInspectionsFiltered(inspectionFiltered)
      }
    }
  
    // Placeholder del filtro de estado realizado
    const placeholderFilterActive = "Filtre realitzat"
  
    // Array con los elementos del filtro de estado realizado
    const dataActive = [
      {
        label: "Pendent fer",
        value: false
      },
      {
        label: "Feta",
        value: true
      },
    ]

    const getListData = (value) => {
      const year = value.$y
      const month = value.$M
      const day = value.$D
      const date = new Date(year, month, day)
      const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() === date.toISOString()
      })
      return listData || []
    }

    const getMonthData = (value) => {
      if (value.month() === 0) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 33)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
      if (value.month() === 1) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 30)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
      if (value.month() === 2) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 33)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
      if (value.month() === 3) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 32)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
      if (value.month() === 4) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 33)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
      if (value.month() === 5) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 32)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
      if (value.month() === 6) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 33)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
      if (value.month() === 7) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 33)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
      if (value.month() === 8) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 32)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
      if (value.month() === 9) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 33)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
      if (value.month() === 10) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 32)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
      if (value.month() === 11) {
        const initialDate = new Date(value.year(), value.month(), 2)
        const finalDate = new Date(value.year(), value.month(), 33)
        const listData = inspectionsFiltered.filter(element => {
          const dateFiltered = element.elementPlanningDate
          const year = dateFiltered.substring(0,4)
          const month = dateFiltered.substring(5,7) - 1
          const day = dateFiltered.substring(8,10)
          const dateElement = new Date(year, month, day)
          return dateElement.toISOString() >= initialDate.toISOString() && dateElement.toISOString() <= finalDate.toISOString()  
        })
        return listData.length
      }
    };

    const dateCellRender = (value) => {
        const listData = getListData(value)
        return (
            <ul className="events">
            {
                listData.map((item) => {
                    let url = '/inspeccions/' + item._id
                    const status = item.elementPlanningValidation === false ? 'error' : 'success'
                    return (
                        <li key={item.elementId}>
                            <Link to={url}><Badge status={status} text={item.elementId.elementName} /></Link>
                        </li>
                    )
                })
            }
            </ul>
        )
    }

    const monthCellRender = (value) => {
      const num = getMonthData(value)
      return num ? (
        <div className={styles.notes_month}>
          <p><span>{num}</span> inspeccions</p>
        </div>
      ) : null
    };

    const cellRender = (current, info) => {
        if (info.type === 'date') return dateCellRender(current)
        if (info.type === 'month') return monthCellRender(current)
        return info.originNode
    }

    return (
        <div className={styles.container} ref={componentRef} >
            <div className={styles.main}>
                <div className={styles.filtereds}>
                    <div className={styles.multiselector}>
                        <Filter
                            data={dataInspections} 
                            handleChange={handleChangeInspection}
                            placeholderFilter={placeholderFilterInspections}
                        />
                    </div>
                    <div className={styles.multiselector}>
                        <Filter
                            data={dataCompany} 
                            handleChange={handleChangeCompany}
                            placeholderFilter={placeholderFilterCompany}
                        />
                    </div>
                    <div className={styles.multiselector}>
                        <Filter
                            data={dataHeadquarters} 
                            handleChange={handleChangeHeadquarter}
                            placeholderFilter={placeholderFilterHeadquarters}
                        />
                    </div>
                    <div className={styles.multiselector}>
                        <Filter
                            data={dataUser} 
                            handleChange={handleChangeUser}
                            placeholderFilter={placeholderFilterUser}
                            value={valueUser}
                        />
                    </div>
                    <div className={styles.multiselector}>
                        <Filter
                            data={dataValidate} 
                            handleChange={handleChangeValidate}
                            placeholderFilter={placeholderFilterValidate}
                        />
                    </div>
                    <div className={styles.multiselector}>
                        <Filter
                            data={dataActive} 
                            handleChange={handleChangeActive}
                            placeholderFilter={placeholderFilterActive}
                            value={false}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.menubuttons}>
                <div className={styles.button}>
                    <Button path="/inspeccions" icon={"fa-list"}/>
                    <Button handleOnClick={handlePrint} icon={"fa-print"}/>
                    <Button path="/inspeccions/new-inspeccio" icon={"fa-circle-plus"}/>
                </div>
            </div>
            <Calendar className={styles.main} cellRender={cellRender} locale={{
                "lang": {
                    "locale": "es_ES",
                    "placeholder": "Select date",
                    "rangePlaceholder": ["Start date", "End date"],
                    "today": "Avui",
                    "now": "Ara",
                    "backToToday": "Back to today",
                    "ok": "OK",
                    "clear": "Clear",
                    "month": "Mes",
                    "year": "Any",
                    "timeSelect": "Select time",
                    "dateSelect": "Select date",
                    "monthSelect": "Choose a month",
                    "yearSelect": "Choose a year",
                    "decadeSelect": "Choose a decade",
                    "yearFormat": "YYYY",
                    "dateFormat": "M/D/YYYY",
                    "dayFormat": "D",
                    "dateTimeFormat": "M/D/YYYY HH:mm:ss",
                    "monthFormat": "MMMM",
                    "monthBeforeYear": true,
                    "previousMonth": "Previous month (PageUp)",
                    "nextMonth": "Next month (PageDown)",
                    "previousYear": "Last year (Control + left)",
                    "nextYear": "Next year (Control + right)",
                    "previousDecade": "Last decade",
                    "nextDecade": "Next decade",
                    "previousCentury": "Last century",
                    "nextCentury": "Next century",
                    "shortWeekDays": ["Dg", "Dl", "Dm", "Dc", "Dj", "Dv", "Ds"],
                    "shortMonths": [
                    "Gen",
                    "Febr",
                    "Març",
                    "Abr",
                    "Maig",
                    "Juny",
                    "Jul",
                    "Ag",
                    "Set",
                    "Oct",
                    "Nov",
                    "Des"
                    ]
                },
                "timePickerLocale": {
                    "placeholder": "Select time"
                },
                "dateFormat": "DD-MM-YYYY",
                "dateTimeFormat": "DD-MM-YYYY HH:mm:ss",
                "weekFormat": "YYYY-wo",
                "monthFormat": "YYYY-MM"
                }
            }/>
        </div>
    )
}

export default CalendarInspections
