import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'
import getAllCompanies from '../../services/companies/getAllCompanies'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import desactiveHeadquarter from '../../services/headquartes/desactiveHeadquarter'

import styles from './Headquarters.module.css'

const Headquarters = () => {
    
  const componentRef = useRef()

  const [ arees, setArees ] = useState([])
  const [ companies, setCompanies ] = useState([])
  const [ areesFiltered, setAreesFiltered ] = useState([])
  const [ update, setUpdate ] = useState(false)

  // const [ filterCompany, setFilterCompany ] = useState([])
  // const [ filterHeadquarters, setFilterHeadquarters ] = useState([])

  useEffect(() => {
    getAllHeadquarters()
    .then(arees => {
      setArees(arees)
      setAreesFiltered(arees)
      // let arrayArees = arees.map(element => element._id)
      // setFilterHeadquarters(arrayArees)
    })
    getAllCompanies()
    .then(companies => {
      setCompanies(companies)
      // let arrayCompanies = companies.map(element => element._id)
      // setFilterCompany(arrayCompanies)
    })
  },[update])

  const handleDesactive = (event) => {
    desactiveHeadquarter(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Taula d'empreses",
    onafterprint: () => alert('Print success')
  })

  const titles = [ "Centre", "Empresa", "Estat", "Accions" ]

  // Función para hacer funcionar el filtro de elementos => por Empresa
  const handleChangeCompany = (value) => {
    if (value.length !== 0) {
      // setFilterCompany(value)
      // let headquarterFilters = arees.filter(element => {
      //   return value.includes(element.areaCompanyId._id) && filterHeadquarters.includes(element._id)
      // })
      // setAreesFiltered(headquarterFilters)
      let areesFiltereds = arees.filter(element =>  value.includes(element.areaCompanyId._id)) 
      setAreesFiltered(areesFiltereds)
    } else {
      // let allCompanies = companies.map(element => element._id)
      // setFilterCompany(allCompanies)
      // let headquarterFilters = arees.filter(element => {
      //   return allCompanies.includes(element.areaCompanyId._id) && filterHeadquarters.includes(element._id)
      // })
      // setAreesFiltered(headquarterFilters)
      setAreesFiltered(arees)
    }
  }

  // Placeholder del filtro de empresa
  const placeholderFilterCompanies = "Filtre empresa"

  // Array con los elementos del filtro de empresa
  const dataCompanies = companies.map(element => {
    return {
      label: `${element.companyName}`,
      value: element._id
    }
  })
  
  // Función para hacer funcionar el filtro de elementos => por Àrea
  const handleChangeHeadquarter = (value) => {
    if (value.length !== 0) {
      // setFilterHeadquarters(value)
      // let headquarterFilters = arees.filter(element => {
      //   return value.includes(element._id) && filterCompany.includes(element.areaCompanyId._id)
      // })
      // setAreesFiltered(headquarterFilters)
      let areesFiltereds = arees.filter(element =>  value.includes(element._id)) 
      setAreesFiltered(areesFiltereds)
    } else {
      // let allHeadquarters = arees.map(element => element._id)
      // setFilterHeadquarters(allHeadquarters)
      // let headquarterFilters = arees.filter(element => {
      //   return allHeadquarters.includes(element._id) && filterCompany.includes(element.areaCompanyId._id)
      // })
      // setAreesFiltered(headquarterFilters)
      setAreesFiltered(arees)
    }
  }

  // Placeholder del filtro de areas
  const placeholderFilterHeadquarters = "Filtre centre"

  // Array con los elementos del filtro de areas
  const dataHeadquarters = arees.map(element => {
    return {
      label: `${element.areaName}`,
      value: element._id
    }
  })

  return (
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.filters}>
            <div className={styles.multiselector}>
              <Filter
                data={dataCompanies} 
                handleChange={handleChangeCompany}
                placeholderFilter={placeholderFilterCompanies}
              />
            </div>
            <div className={styles.multiselector}>
              <Filter
                data={dataHeadquarters} 
                handleChange={handleChangeHeadquarter}
                placeholderFilter={placeholderFilterHeadquarters}
              />
            </div>
          </div>
        <div className={styles.button}>
            <Button handleOnClick={handlePrint} icon={"fa-print"}/>
            <Button path="/centres/new-centre" icon={"fa-circle-plus"}/>
        </div>
      </div>
      <div className={styles.listElements} ref={componentRef}>
        <div className={`${styles.table} ${styles.ver3}`}>
          <table data-vertable="ver3">
            <thead>
              <tr className={`${styles.rows} ${styles.head}`}>
                {
                  titles.map((element, index) =>{
                    return <th className={styles.columns} key={index}>{element}</th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                areesFiltered.map(element => {
                  let url = '/centres/' + element._id
                  return (
                    <tr className={styles.rows} id={element._id} key={element._id}>
                      <td className={styles.columns}>{element.areaName}</td>
                      <td className={styles.columns}>{element.areaCompanyId.companyName}</td>
                      <td className={styles.columns}>{element.areaActive ? "Actiu" : "No actiu"}</td>
                      <td className={styles.columns}>
                        <Link to={url}><i className="fa-solid fa-pen-to-square"></i></Link>
                        <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
                      </td>
                    </tr>  
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Headquarters
