import { LocalStorage } from '../localStorage/LocalStorage.services'

const getAllInspections = async () => {

    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/planning`,
            {
                headers: {
                    'auth-token': LocalStorage.getItem("token")
                },
            }
        )

        if (response.ok) {
            const data = await response.json()
            let dataFilteredUser
            if (LocalStorage.getItem("userProfile") !== "Administrador") {
                dataFilteredUser = data.filter(element => {
                    return element.elementUserPlanningId._id === LocalStorage.getItem("id")
                })
            } else {
                dataFilteredUser = data
            }
            const dataNoDeleted = dataFilteredUser.filter(element => {
                return element.elementPlanningDeleted === false
            })
            return  dataNoDeleted
        }

    } catch (error) {
        return {message: error.message || "Something went wrong"}
    }
    
}
    
export default getAllInspections;