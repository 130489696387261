import { LocalStorage } from '../localStorage/LocalStorage.services'

const getOperations = async () => {

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/operations`,
            {
                headers: {
                    'auth-token': LocalStorage.getItem("token"),
                },
            }
        )

        if (response.ok) {
            const data = await response.json()
            return data
        }
    } catch (error) {
        return {message: error.message || "Something went wrong"}
    }
    
}
    
export default getOperations;