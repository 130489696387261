import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'
import getAllUpkeeps from '../../services/upkeeps/getAllUpkeeps'
import getAllUpkeepTypes from '../../services/upkeepTypes/getAllUpkeepTypes'
import desactiveUpkeep from '../../services/upkeeps/desactiveUpkeep'

import styles from './Upkeep.module.css'

const Upkeep = () => {

  const componentRef = useRef()
  
  const [ upkeeps, setUpkeeps ] = useState([])
  const [ upkeepsFiltered, setUpkeepsFiltered ] = useState([])
  const [ upkeepTypes, setUpkeepTypes ] = useState([])
  const [ update, setUpdate ] = useState(false)

  useEffect(() => {
    getAllUpkeeps()
    .then(upkeep => {
      setUpkeeps(upkeep)
      setUpkeepsFiltered(upkeep)
    })
    getAllUpkeepTypes()
    .then(upkeepTypes => {
      setUpkeepTypes(upkeepTypes)
    })
  },[update])

  const handleDesactive = (event) => {
    desactiveUpkeep(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Taula d'empreses",
    onafterprint: () => alert('Print success')
  })
  
  const titles = [ "Control", "Descripció", "Tipus control", "L.Superior", "L.Inferior", "Tipus Valor", "Estat", "Accions" ]

  // Función para hacer funcionar el filtro de elementos => por Mantenimento
  const handleChangeUpkeep = (value) => {
    if (value.length !== 0) {
      let upkeepsFiltereds = upkeeps.filter(element =>  value.includes(element._id)) 
      setUpkeepsFiltered(upkeepsFiltereds)
    } else {
      setUpkeepsFiltered(upkeeps)
    }
  }

  // Placeholder del filtro de mantenimento
  const placeholderFilterUpkeeps = "Filtre manteniment"

  // Array con los elementos del filtro de mantenimiento
  const dataUpkeeps = upkeeps.map(element => {
    return {
      label: `${element.upkeepName}`,
      value: element._id
    }
  })
  
  // Función para hacer funcionar el filtro de elementos => por tipus de manteniment
  const handleChangeUpkeepType = (value) => {
    if (value.length !== 0) {
      let upkeepsFiltereds = upkeeps.filter(element =>  value.includes(element.upkeepTypeId._id)) 
      setUpkeepsFiltered(upkeepsFiltereds)
    } else {
      setUpkeepsFiltered(upkeeps)
    }
  }

  // Placeholder del filtro de tipus de manteniment
  const placeholderFilterUpkeepType = "Filtre tipus manteniment"

  // Array con los elementos del filtro de tipus de manteniment
  const dataUpkeepType = upkeepTypes.map(element => {
    return {
      label: `${element.upkeepTypeName}`,
      value: element._id
    }
  })

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.filters}>
        <div className={styles.multiselector}>
          <Filter
            data={dataUpkeeps} 
            handleChange={handleChangeUpkeep}
            placeholderFilter={placeholderFilterUpkeeps}
          />
        </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataUpkeepType} 
              handleChange={handleChangeUpkeepType}
              placeholderFilter={placeholderFilterUpkeepType}
            />
          </div>
        </div>
        <div className={styles.button}>
          <Button handleOnClick={handlePrint} icon={"fa-print"}/>
          <Button path="/manteniments/new-manteniment" icon={"fa-circle-plus"}/>
        </div>
      </div>
      <div className={styles.listElements} ref={componentRef}>
        <div className={`${styles.table} ${styles.ver3}`}>
          <table data-vertable="ver3">
            <thead>
              <tr className={`${styles.rows} ${styles.head}`}>
                {
                  titles.map((element, index) =>{
                    return <th className={styles.columns} key={index}>{element}</th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                upkeepsFiltered.map(element => {
                  let url = '/manteniments/' + element._id
                  let text = ""
                  switch (element.upkeepValue) {
                    case 'number':
                      text = "Numèric"
                      break;
                    case 'string':
                      text = "Text"
                      break
                    case 'boolean':
                      text = "Si/No"
                      break
                    default:
                      break
                  }
                  return (
                    <tr className={styles.rows} id={element._id} key={element._id}>
                      <td className={styles.columns}>{element.upkeepName}</td>
                      <td className={styles.columns}>{element.upkeepDescription}</td>
                      <td className={styles.columns}>{element.upkeepTypeId.upkeepTypeName} ({element.upkeepTypeId.upkeepTypeUnits})</td>
                      <td className={styles.columns}>{element.upkeepLimitUp}</td>
                      <td className={styles.columns}>{element.upkeepLimitDown}</td>
                      <td className={styles.columns}>{text}</td>
                      <td className={styles.columns}>{element.upkeepActive ? "Actiu" : "No actiu"}</td>
                      <td className={styles.columns}>
                        <Link to={url}><i className="fa-solid fa-pen-to-square"></i></Link>
                        <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
                      </td>
                    </tr>  
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Upkeep
