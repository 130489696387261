import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'
import getAllCompanies from '../../services/companies/getAllCompanies'
import desactiveCompany from '../../services/companies/desactiveCompany'

import styles from './Companies.module.css'

const Companies = () => {

  const componentRef = useRef()
  
  const [ companies, setCompanies ] = useState([])
  const [ companiesFiltered, setCompaniesFiltered ] = useState([])
  const [ update, setUpdate ] = useState(false)

  const [ filterCompany, setFilterCompany ] = useState([])

  useEffect(() => {
    getAllCompanies()
    .then(companies => {
      setCompanies(companies)
      setCompaniesFiltered(companies)
      let arrayCompanies = companies.map(element => element._id)
      setFilterCompany(arrayCompanies)
    })
  },[update])

  // Función desactivar compañia (como un deleted pero si borrar para conservar informacion)
  const handleDesactive = (event) => {
    desactiveCompany(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Taula d'empreses",
    onafterprint: () => alert('Print success')
  })

  const titles = [ "Empresa", "CIF", "Estat", "Accions" ]

  // Función para hacer funcionar el filtro de elementos => por Empresa
  const handleChangeCompany = (value) => {
    if (value.length !== 0) {
      let companiesFiltered = companies.filter(element =>  value.includes(element._id)) 
      setCompaniesFiltered(companiesFiltered)
    } else {
      setCompaniesFiltered(companies)
    }
  }

  // Placeholder del filtro de empresa
  const placeholderFilterCompanies = "Filtre empresa"

  // Array con los elementos del filtro de empresa
  const dataCompanies = companies.map(element => {
    return {
      label: `${element.companyName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por CIF Empresa
  const handleChangeCompanyCIF = (value) => {
    if (value.length !== 0) {
      let companiesFiltered = companies.filter(element =>  value.includes(element._id)) 
      setCompaniesFiltered(companiesFiltered)
    } else {
      setCompaniesFiltered(companies)
    }
  }

  // Placeholder del filtro de empresa CIF
  const placeholderFilterCompaniesCIF = "Filtre CIF empresa"

  // Array con los elementos del filtro de empresa CIF
  const dataCompaniesCIF = companies.map(element => {
    return {
      label: `${element.companyCIF}`,
      value: element._id
    }
  })

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.filters}>
          <div className={styles.multiselector}>
            <Filter
              data={dataCompanies} 
              handleChange={handleChangeCompany}
              placeholderFilter={placeholderFilterCompanies}
            />
          </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataCompaniesCIF} 
              handleChange={handleChangeCompanyCIF}
              placeholderFilter={placeholderFilterCompaniesCIF}
            />
          </div>
        </div>
        <div className={styles.button}>
            <Button handleOnClick={handlePrint} icon={"fa-print"}/>
            <Button path="/empreses/new-empresa" icon={"fa-circle-plus"}/>
        </div>
      </div>
      <div className={styles.listElements} ref={componentRef}>
        <div className={`${styles.table} ${styles.ver3}`}>
          <table data-vertable="ver3">
            <thead>
              <tr className={`${styles.rows} ${styles.head}`}>
                {
                  titles.map((element, index) =>{
                    return <th className={styles.columns} key={index}>{element}</th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                companiesFiltered.map(element => {
                  let url = '/empreses/' + element._id
                  return (
                    <tr className={styles.rows} id={element._id} key={element._id}>
                      <td className={styles.columns}>{element.companyName}</td>
                      <td className={styles.columns}>{element.companyCIF}</td>
                      <td className={styles.columns}>{element.companyActive ? "Actiu" : "No actiu"}</td>
                      <td className={styles.columns}>
                        <Link to={url}><i className="fa-solid fa-pen-to-square"></i></Link>
                        <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
                      </td>
                    </tr>  
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Companies
