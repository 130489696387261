import { Link } from 'react-router-dom'
import styles from './Porlet.module.css'

const Porlet = (props) => {
    return (
        <div className={`${styles["porlet"]} ${styles[props.type]}`}>
            {props.data === undefined ? 
                <div className={`${styles["lds-roller"]} ${styles["porlet-data"]}}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> :
                <span className={styles["porlet-data"]}>{props.data}</span>
            }
            <Link to={props.link} className={`${styles["porlet-name"]}`}>{props.name}</Link>
        </div>
    )
}

export default Porlet