import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import Modal from '../../components/Modal/Modal'
import getElementById from '../../services/elements/getElementById'
import getAllCompanies from '../../services/companies/getAllCompanies'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import postElement from '../../services/elements/postElement'
import putElement from '../../services/elements/putElement'

import styles from './NewElement.module.css'

const NewElement = () => {

    const { id } = useParams()
    const [ newElement, setNewElement ] = useState({})
    const [ active, setActive ] = useState(true)
    const [ companies, setCompanies ] = useState([])
    const [ headquarters, setHeadquarters ] = useState([])
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)

    useEffect(() => {
        getAllCompanies()
        .then(companies => {
            setCompanies(companies)
        })
        getAllHeadquarters()
        .then(headquarters => {
            setHeadquarters(headquarters)
        })
        if (id !== undefined) {
            getElementById(id)
            .then(element => {
                setNewElement(element)
                setActive(element.elementActive)
            })
        }
    },[id])

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        formData.append('elementActive', event.target.elementActive.checked)
        const formJson = Object.fromEntries(formData.entries())
        if (id !== undefined) {
            putElement(formJson, id) 
            .then(newElement => {
                setNewElement(newElement);
                setDisable(false)
                setTimeout(() => {
                    setDisable(true)
                },3000)
            })
        } else {
            postElement(formJson) 
            .then(newElement => {
                setNewElement(newElement);
                setDisable(false)
                setTimeout(() => {
                    setDisable(true)
                },3000)
            })
        }
    }

    const handleChange = (event) => {
        if (event.target.id === "elementActive") {
            setActive(event.target.checked)
        } else {
            setNewElement(event.target.value);
        }
    }

    return (
        <div className={styles.container}>
            <h2>{id ? "Editar empresa" : "Alta nova empresa"}</h2>
            <div className={disable && styles.disable}>
                <Modal error={error} id={id} text={id ? "Element modificat correctament" : "Element creat correctament"}></Modal>
            </div>
            <form onSubmit={handleSubmit}>
                <label for="elementName">Nom</label>
                <input type='text' onChange={handleChange} name="elementName" value={newElement.elementName}></input>
                <label for="elementReference">Referència</label>
                <input type='text' onChange={handleChange} name="elementReference" value={newElement.elementReference}></input>
                <label for="elementDescription">Descripció</label>
                <textarea rows={3} type='text' onChange={handleChange} name="elementDescription" value={newElement.elementDescription}></textarea>
                <label for="elementUbication">Ubicació</label>
                <input type='text' onChange={handleChange} name="elementUbication" value={newElement.elementUbication}></input>
                <label for="elementCompanyId">Empresa</label>
                <select onChange={handleChange} name="elementCompanyId" value={newElement.elementCompanyId} id="elementCompanyId">
                    <option value="selecciona">- Selecciona -</option>
                    {
                        companies.map( element => {
                            return (
                                <option value={element._id}>{element.companyName}</option>
                            )
                        })
                    }
                </select>
                <label for="elementAreaId">Àrea</label>
                <select onChange={handleChange} name="elementAreaId" value={newElement.elementAreaId}>
                    <option value="selecciona">- Selecciona -</option>
                    {
                        headquarters.map( element => {
                            return (
                                <option value={element._id}>{element.areaName}</option>
                            )
                        })
                    }
                </select>
                <label for="elementActive">Actiu</label>
                <input type='checkbox' onChange={handleChange} name="elementActive" id="elementActive" checked={active} className={styles.checking}/>
                <div className={styles.btn_container}>
                    <button className={styles.btn_add} type='submit'>{id ? <i className="fa-regular fa-floppy-disk"></i> : <i className="fa-solid fa-circle-plus"></i>}{id ? "Guardar" : "Crear"}</button>
                </div>
            </form>
        </div>
    )
}

export default NewElement