import styles from './Modal.module.css'

const Modal = (props) => {
    return (
        <div className={`${styles.modal} ${props.error.length === 0 ? styles.correct : styles.incorrect }`}>
            <p>{props.error.length === 0 ? props.text : props.error}</p>
            <div className={styles.loader}></div>
        </div>
    )
}

export default Modal
