import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'
import getAllUsers from '../../services/users/getAllUsers'
import getAllCompanies from '../../services/companies/getAllCompanies'
import desactiveUser from '../../services/users/desactiveUser'

import styles from './Users.module.css'

const Users = () => {

  const componentRef = useRef()
  
  const [ users, setUsers ] = useState([])
  const [ usersFiltered, setUsersFiltered ] = useState([])
  const [ companies, setCompanies ] = useState([])
  const [ update, setUpdate ] = useState(false)
  
  useEffect(() => {
    getAllUsers()
    .then(elements => {
      setUsers(elements)
      setUsersFiltered(elements)
    })
    getAllCompanies()
    .then(companies => {
      setCompanies(companies)
    })
  },[update])

  const handleDesactive = (event) => {
    desactiveUser(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Taula d'empreses",
    onafterprint: () => alert('Print success')
  })
  
  const titles = [ "Usuari", "DNI", "E-mail", "Perfil", "Empresa", "Estat", "Accions" ]

  // Función para hacer funcionar el filtro de elementos => por usuari
  const handleChangeUser = (value) => {
    if (value.length !== 0) {
      let usersFiltered = users.filter(element =>  value.includes(element._id)) 
      setUsersFiltered(usersFiltered)
    } else {
      setUsersFiltered(users)
    }
  }

  // Placeholder del filtro de usuaris
  const placeholderFilterUsers = "Filtre usuari"

  // Array con los elementos del filtro de usuaris
  const dataHeadquarters = users.map(element => {
    return {
      label: `${element.userName} ${element.userLastName1} ${element.userLastName2}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por Empresa
  const handleChangeCompany = (value) => {
    if (value.length !== 0) {
      let companiesFiltered = users.filter(element =>  value.includes(element.userCompanyId._id)) 
      setUsersFiltered(companiesFiltered)
    } else {
      setUsersFiltered(users)
    }
  }

  // Placeholder del filtro de usuarios
  const placeholderFilterCompanies = "Filtre empresa"

  // Array con los elementos del filtro de usuarios
  const dataCompanies = companies.map(element => {
    return {
      label: `${element.companyName}`,
      value: element._id
    }
  })
  
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.filters}>
          <div className={styles.multiselector}>
            <Filter
              data={dataHeadquarters} 
              handleChange={handleChangeUser}
              placeholderFilter={placeholderFilterUsers}
            />
          </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataCompanies} 
              handleChange={handleChangeCompany}
              placeholderFilter={placeholderFilterCompanies}
            />
          </div>
        </div>
        <div className={styles.button}>
          <Button handleOnClick={handlePrint} icon={"fa-print"}/>
          <Button path="/usuaris/new-user" icon={"fa-circle-plus"}/>
        </div>
      </div>
      <div className={styles.listElements} ref={componentRef}>
        <div className={`${styles.table} ${styles.ver3}`}>
          <table data-vertable="ver3">
            <thead>
              <tr className={`${styles.rows} ${styles.head}`}>
                {
                  titles.map((element, index) =>{
                    return <th className={styles.columns} key={index}>{element}</th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                usersFiltered.map(element => {
                  let url = '/usuaris/' + element._id
                  return (
                    <tr className={styles.rows} id={element._id} key={element._id}>
                      <td className={styles.columns}>{element.userLastName1} {element.userLastName2}, {element.userName}</td>
                      <td className={styles.columns}>{element.userDNI}</td>
                      <td className={styles.columns}>{element.userEmail}</td>
                      <td className={styles.columns}>{element.userProfile}</td>
                      <td className={styles.columns}>{element.userCompanyId.companyName}</td>
                      <td className={styles.columns}>{element.userActive ? "Actiu" : "No actiu"}</td>
                      <td className={styles.columns}>
                        <Link to={url}><i className="fa-solid fa-pen-to-square"></i></Link>
                        <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
                      </td>
                    </tr>  
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Users
