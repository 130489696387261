import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'
import getAllElements from '../../services/elements/getAllElements'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import getAllCompanies from '../../services/companies/getAllCompanies'
import desactiveElement from '../../services/elements/desactiveElement'

import styles from './Elements.module.css'

const Elements = () => {
  
  const componentRef = useRef()

  const [ elements, setElements ] = useState([])
  const [ headquarters, setHeadquarters ] = useState([])
  const [ companies, setCompanies ] = useState([])
  const [ elementsFiltered, setElementsFiltered ] = useState([])
  const [ update, setUpdate ] = useState(false)

  const [ filterCompany, setFilterCompany ] = useState([])
  const [ filterHeadquarters, setFilterHeadquarters ] = useState([])

  useEffect(() => {
    getAllElements()
    .then(elements => {
      setElements(elements)
      setElementsFiltered(elements)
    })
    getAllCompanies()
    .then(companies => {
      setCompanies(companies)
      let arrayCompanies = companies.map(element => element._id)
      setFilterCompany(arrayCompanies)
      
    })
    getAllHeadquarters()
    .then(headquarters => {
      setHeadquarters(headquarters)
      let arrayArees = headquarters.map(element => element._id)
      setFilterHeadquarters(arrayArees)
    })
  },[update])

  const handleDesactive = (event) => {
    desactiveElement(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Taula d'empreses",
    onafterprint: () => alert('Print success')
  })

  const titles = [ "Ref.", "Element", "Ubicació", "Centre", "Empresa", "Estat", "Accions" ]

    // Función para hacer funcionar el filtro de elementos => por Empresa
  const handleChangeCompany = (value) => {
    if (value.length !== 0) {
      setFilterCompany(value)
      let elementsFilters = elements.filter(element => {
        return value.includes(element.elementCompanyId._id) && filterHeadquarters.includes(element.elementAreaId._id)  
      })
      setElementsFiltered(elementsFilters)
    } else {
      let allCompanies = companies.map(element => element._id)
      setFilterCompany(allCompanies)
      let elementsFiltered = elements.filter(element =>  {
        return allCompanies.includes(element.elementCompanyId._id) && filterHeadquarters.includes(element.elementAreaId._id)
      }) 
      setElementsFiltered(elementsFiltered)
    }
  }

  // Placeholder del filtro de usuarios
  const placeholderFilterCompanies = "Filtre empresa"

  // Array con los elementos del filtro de usuarios
  const dataCompanies = companies.map(element => {
    return {
      label: `${element.companyName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por Àrea
  const handleChangeHeadquarter = (value) => {
    if (value.length !== 0) {
      setFilterHeadquarters(value)
      let elementsFilters = elements.filter(element => {
        return value.includes(element.elementAreaId._id) && filterCompany.includes(element.elementCompanyId._id)
      })
      setElementsFiltered(elementsFilters)
    } else {
      let allHeadquarters = headquarters.map(element => element._id)
      setFilterHeadquarters(allHeadquarters)
      let elementsFiltered = elements.filter(element =>  {
        return  allHeadquarters.includes(element.elementAreaId._id) && filterCompany.includes(element.elementCompanyId._id)
      }) 
      setElementsFiltered(elementsFiltered)
    }
  }

  // Placeholder del filtro de areas
  const placeholderFilterHeadquarters = "Filtre centre"

  // Array con los elementos del filtro de areas
  const dataHeadquarters = headquarters.map(element => {
    return {
      label: `${element.areaName}`,
      value: element._id
    }
  })

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.filters}>
          <div className={styles.multiselector}>
            <Filter
                data={dataCompanies} 
                handleChange={handleChangeCompany}
                placeholderFilter={placeholderFilterCompanies}
              />
          </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataHeadquarters} 
              handleChange={handleChangeHeadquarter}
              placeholderFilter={placeholderFilterHeadquarters}
            />
          </div>
        </div>
        <div className={styles.button}>
            <Button handleOnClick={handlePrint} icon={"fa-print"}/>
            <Button path="/elements/new-element" icon={"fa-circle-plus"}/>
        </div>
      </div>
      <div className={styles.listElements} ref={componentRef}>
        <div className={`${styles.table} ${styles.ver3}`}>
          <table data-vertable="ver3">
            <thead>
              <tr className={`${styles.rows} ${styles.head}`}>
                {
                  titles.map((element, index) =>{
                    return <th className={styles.columns} key={index}>{element}</th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                elementsFiltered.map(element => {
                  let url1 = '/elements/' + element._id
                  let url2 = '/elements/grafics/' + element._id
                  return (
                    <tr className={styles.rows} id={element._id} key={element._id}>
                      <td className={styles.columns}>{element.elementReference}</td>
                      <td className={styles.columns}>{element.elementName}</td>
                      <td className={styles.columns}>{element.elementUbication}</td>
                      <td className={styles.columns}>{element.elementAreaId.areaName}</td>
                      <td className={styles.columns}>{element.elementCompanyId.companyName}</td>
                      <td className={styles.columns}>{element.elementActive ? "Actiu" : "No actiu"}</td>
                      <td className={styles.columns}>
                        <Link to={url2}><i className="fa-solid fa-chart-line"></i></Link>
                        <Link to={url1}><i className="fa-solid fa-pen-to-square"></i></Link>
                        <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
                      </td>
                    </tr>  
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Elements
