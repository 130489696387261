import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'
import getAllInspections from '../../services/inspections/getAllInspections'
import getAllElements from '../../services/elements/getAllElements'
import getAllCompanies from '../../services/companies/getAllCompanies'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import getAllUsers from '../../services/users/getAllUsers'

import styles from './Actions.module.css'

const Actions = () => {

    const componentRef = useRef()

    const [ inspections, setInpections ] = useState([])
    const [ inpectionsFiltered, setInpectionsFiltered ] = useState([])
    const [ elements, setElements ] = useState([])
    const [ companies, setCompanies ] = useState([])
    const [ headquarters, setHeadquarters ] = useState([])
    const [ users, setUsers ] = useState([])
    const [ update, setUpdate ] = useState(false)

    const [ filterElements, setFilterElements ] = useState([])
    const [ filterUsers, setFilterUsers ] = useState([])
    const [ filterCompany, setFilterCompany ] = useState([])
    const [ filterHeadquarters, setFilterHeadquarters ] = useState([])
    const [ filterActive, setFilterActive ] = useState([ true, false ])
    const [ filterValidate, setFilterValidate ] = useState([ true, false ])
    
    useEffect(() => {
      getAllInspections()
      .then(inspection => {
        let filteredInspections = inspection.filter(elements => elements.elementActions)
        setInpections(filteredInspections)
        setInpectionsFiltered(filteredInspections)
      })
      getAllCompanies()
      .then(companies => {
        setCompanies(companies)
        let arrayCompanies = companies.map(element => element._id)
        setFilterCompany(arrayCompanies)
        
      })
      getAllHeadquarters()
      .then(headquarters => {
        setHeadquarters(headquarters)
        let arrayArees = headquarters.map(element => element._id)
        setFilterHeadquarters(arrayArees)
      })
      getAllUsers()
      .then(users => {
        setUsers(users)
        let arrayUsers = users.map(element => element._id)
        setFilterUsers(arrayUsers)
      })
      getAllElements()
      .then(elements => {
        setElements(elements)
        let arrayElements = elements.map(element => element._id)
        setFilterElements(arrayElements)
      })
    },[update])
  
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: "Taula de inspeccions",
      onafterprint: () => alert('Print success')
    })
  
    const titles = [ "Element", "Centre", "Accions", "Realitzada", "Usuari", "Accions" ]
  
      // Función para hacer funcionar el filtro de elementos => por inspeccion
  const handleChangeInspection = (value) => {
    if (value.length !== 0) {
      setFilterElements(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId)  
      })
      setInpectionsFiltered(inspectionsFilters)
    } else {
      let allElements = elements.map(element => element._id)
      setFilterElements(allElements)
      let inspectionFiltered = inspections.filter(element =>  {
        return allElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de inspecciones
  const placeholderFilterInspections = "Filtre inspeccions"

  // Array con los elementos del filtro de inspecciones
  const dataInspections = elements.map(element => {
    return {
      label: `${element.elementName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por empresa
  const handleChangeCompany = (value) => {
    if (value.length !== 0) {
      setFilterCompany(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId)  
      })
      setInpectionsFiltered(inspectionsFilters)
    } else {
      let allCompanies = companies.map(element => element._id)
      setFilterCompany(allCompanies)
      let inspectionFiltered = inspections.filter(element =>  {
        return  allCompanies.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de empresa
  const placeholderFilterCompany = "Filtre empresa"

  // Array con los elementos del filtro de empresa
  const dataCompany = companies.map(element => {
    return {
      label: `${element.companyName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por Àrea
  const handleChangeHeadquarter = (value) => {
    if (value.length !== 0) {
      setFilterHeadquarters(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id)
      })
      setInpectionsFiltered(inspectionsFilters)
    } else {
      let allHeadquarters = headquarters.map(element => element._id)
      setFilterHeadquarters(allHeadquarters)
      let inspectionFiltered = inspections.filter(element =>  {
        return  allHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de areas
  const placeholderFilterHeadquarters = "Filtre centre"

  // Array con los elementos del filtro de areas
  const dataHeadquarters = headquarters.map(element => {
    return {
      label: `${element.areaName}`,
      value: element._id
    }
  })
  
  // Función para hacer funcionar el filtro de elementos => por usuario planificado
  const handleChangeUser = (value) => {
    
    if (value.length !== 0) {
      setFilterUsers(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation)
      })
      setInpectionsFiltered(inspectionsFilters)
    } else {
      let allUsers = users.map(element => element._id)
      setFilterUsers(allUsers)
      let inspectionFiltered = inspections.filter(element =>  {
        return  allUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de usuario planificado
  const placeholderFilterUser = "Filtre usuari planificat"

  // Array con los elementos del filtro de usuario planificado
  const dataUser = users.map(element => {
    return {
      label: `${element.userName} ${element.userLastName1} ${element.userLastName2}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por estado
  const handleChangeValidate = (value) => {
    if (value.length !== 0) {
      setFilterValidate(value)
      let elementsFiltereds = inspections.filter(element => {
        return value.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive)
      })    
      setInpectionsFiltered(elementsFiltereds)
    } else {
      let allValidations = [ true, false ]
      setFilterValidate(allValidations)
      let inspectionFiltered = inspections.filter(element =>  {
        return allValidations.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de estado
  const placeholderFilterValidate = "Filtre estat"

  // Array con los elementos del filtro de estado
  const dataValidate = [
    {
      label: "Pendent validar",
      value: false
    },
    {
      label: "Validada",
      value: true
    },
  ]
  
  // Función para hacer funcionar el filtro de elementos => por estado realizado
  const handleChangeActive = (value) => {
    if (value.length !== 0) {
      setFilterActive(value)
      let elementsFiltereds = inspections.filter(element => {
        return value.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id)
      })    
      setInpectionsFiltered(elementsFiltereds)
    } else {
      let allStates = [ true, false ]
      setFilterActive(allStates)
      let inspectionFiltered = inspections.filter(element =>  {
        return allStates.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de estado realizado
  const placeholderFilterActive = "Filtre realitzat"

  // Array con los elementos del filtro de estado realizado
  const dataActive = [
    {
      label: "Pendent fer",
      value: false
    },
    {
      label: "Feta",
      value: true
    },
  ]
  
    return (
      <div className={styles.container}>
          <div className={styles.main}>
            <div className={styles.filtereds}>
              <div className={styles.multiselector}>
                <Filter
                  data={dataInspections} 
                  handleChange={handleChangeInspection}
                  placeholderFilter={placeholderFilterInspections}
                />
              </div>
              <div className={styles.multiselector}>
                <Filter
                  data={dataCompany} 
                  handleChange={handleChangeCompany}
                  placeholderFilter={placeholderFilterCompany}
                />
              </div>
              <div className={styles.multiselector}>
                <Filter
                  data={dataHeadquarters} 
                  handleChange={handleChangeHeadquarter}
                  placeholderFilter={placeholderFilterHeadquarters}
                />
              </div>
              <div className={styles.multiselector}>
                <Filter
                  data={dataUser} 
                  handleChange={handleChangeUser}
                  placeholderFilter={placeholderFilterUser}
                />
              </div>
              <div className={styles.multiselector}>
                <Filter
                  data={dataValidate} 
                  handleChange={handleChangeValidate}
                  placeholderFilter={placeholderFilterValidate}
                />
              </div>
              <div className={styles.multiselector}>
                <Filter
                  data={dataActive} 
                  handleChange={handleChangeActive}
                  placeholderFilter={placeholderFilterActive}
                />
              </div>
            </div>
            <div className={styles.button}>
              <Button handleOnClick={handlePrint} icon={"fa-print"}/>
            </div>
          </div>
          <div className={styles.listElements} ref={componentRef}>
          <div className={`${styles.table} ${styles.ver3}`}>
            <table data-vertable="ver3">
              <thead>
                <tr className={`${styles.rows} ${styles.head}`}>
                  {
                    titles.map((element, index) =>{
                      return <th className={styles.columns} key={index}>{element}</th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  inpectionsFiltered.map(element => {
                    let url = '/accions/' + element._id
                    let area = headquarters.filter(area => area._id === element.elementId.elementAreaId)
                    return (
                      <tr className={styles.rows} id={element._id} key={element._id}>
                        <td className={styles.columns}><Link to={url}>{element.elementId.elementName}</Link></td>
                        <td className={styles.columns}>{area[0] && area[0].areaName}</td>
                        <td className={styles.columns}>{element.elementActions && element.elementActions }</td>
                        <td className={styles.columns}>{element.elementDoDate && new Date(element.elementDoDate).toLocaleDateString()}</td>
                        <td className={styles.columns}>{element.elementUserDoId && element.elementUserDoId.userName} {element.elementUserDoId && element.elementUserDoId.userLastName1} {element.elementUserDoId && element.elementUserDoId.userLastName2}</td>
                        <td className={styles.columns}>
                          <Link to={url}><i className="fa-solid fa-eye"></i></Link>
                        </td>
                      </tr>  
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
    </div>
  )
}

export default Actions