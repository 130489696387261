import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { LocalStorage } from '../../services/localStorage/LocalStorage.services'
import moment from 'moment'
import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'
import getAllInspections from '../../services/inspections/getAllInspections'
import getAllElements from '../../services/elements/getAllElements'
import getAllCompanies from '../../services/companies/getAllCompanies'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import getAllUsers from '../../services/users/getAllUsers'
import desactiveInspection from '../../services/inspections/desactiveInspection'
import validateInspection from '../../services/inspections/validateInspection'

import styles from './Inspections.module.css'

const Inspections = () => {

  const componentRef = useRef()

  const [ pageSize, setPageSize ] = useState(10)
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ numberOfPages, setNumberOfPages ] = useState(0)
  const [ currentDataDisplayed, setCurrentDataDisplayed ] = useState([])
  const [ previousAllowed, setPreviousAllowed ] = useState(false)
  const [ nextAllowed, setNextAllowed ] = useState(true)

  const [ inspections, setInpections ] = useState([])
  const [ inpectionsFiltered, setInpectionsFiltered ] = useState([])
  const [ elements, setElements ] = useState([])
  const [ companies, setCompanies ] = useState([])
  const [ headquarters, setHeadquarters ] = useState([])
  const [ users, setUsers ] = useState([])
  const [ update, setUpdate ] = useState(false)
  const [ isChecked, setIsChecked ] = useState([])

  const [ filterElements, setFilterElements ] = useState([])
  const [ filterUsers, setFilterUsers ] = useState([LocalStorage.getItem("id")])
  const [ filterCompany, setFilterCompany ] = useState([])
  const [ filterHeadquarters, setFilterHeadquarters ] = useState([])
  const [ filterActive, setFilterActive ] = useState([ false ])
  const [ filterValidate, setFilterValidate ] = useState([ true, false ])
  const [ filterInitialDate, setFilterInitialDate ] = useState(null)
  const [ filterFinalDate, setFilterFinalDate ] = useState(null)
  
  useEffect(() => {
    getAllInspections()
    .then(inspection => {
      setInpections(inspection)
      let inspectionsFilters = inspection.filter(element => {
        return element.elementPlanningActive === false && element.elementPlanningValidation === false && element.elementUserPlanningId._id === LocalStorage.getItem("id")  
      })
      setInpectionsFiltered(inspectionsFilters)
    })
    getAllCompanies()
    .then(companies => {
      setCompanies(companies)
      let arrayCompanies = companies.map(element => element._id)
      setFilterCompany(arrayCompanies)
      
    })
    getAllHeadquarters()
    .then(headquarters => {
      setHeadquarters(headquarters)
      let arrayArees = headquarters.map(element => element._id)
      setFilterHeadquarters(arrayArees)
    })
    getAllUsers()
    .then(users => {
      setUsers(users)
      let arrayUsers = users.map(element => element._id)
      setFilterUsers(arrayUsers)
    })
    getAllElements()
    .then(elements => {
      setElements(elements)
      let arrayElements = elements.map(element => element._id)
      setFilterElements(arrayElements)
    })
  },[update])

  useEffect(() => {
    inpectionsFiltered &&
      setCurrentDataDisplayed(() => {
        const page = inpectionsFiltered?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        return page
      })
      setIsChecked(() => {
        const page = inpectionsFiltered?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        return (new Array(page.length).fill(false))
      })
      setPreviousAllowed(() => currentPage > 1)
      setNextAllowed(() => currentPage < numberOfPages)
      setNumberOfPages(Math.ceil(inpectionsFiltered.length / pageSize))
  }, [currentPage, inpectionsFiltered, pageSize, update])

  const handlePagination = (action) => {
    if (action === "prev") {
      if (!previousAllowed) return
      setCurrentPage((prevState) => (prevState -= 1))
    }
    if (action === "next") {
      if (!nextAllowed) return
      setCurrentPage((prevState) => (prevState += 1))
    }
  }

  const handleDesactive = (event) => {
    desactiveInspection(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  const handleValidate = (event) => {
    validateInspection(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  const handleDesactiveMultiple = (event) => {
    const arrayId = []
    for (let index = 0; index < isChecked.length; index++) {
      arrayId[index] = currentDataDisplayed[index]._id
    }
    for (let index = 0; index < isChecked.length; index++) {
      isChecked[index] && desactiveInspection(arrayId[index])
    }
    setUpdate(!update)
  }
  
  const handleValidateMultiple = (event) => {
    const arrayId = []
    for (let index = 0; index < isChecked.length; index++) {
      arrayId[index] = currentDataDisplayed[index]._id
    }
    for (let index = 0; index < isChecked.length; index++) {
      isChecked[index] && currentDataDisplayed[index].elementDoDate && validateInspection(arrayId[index])
    }
    setUpdate(!update)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Taula de inspeccions",
    onafterprint: () => alert('Print success')
  })

  const titles = [ "", "Inspecció", "Centre", "Manteniment", "Valors", "Avis", "Planificada", "Realitzada", "Usuari", "Acció", "Estat", "Accions" ]

  // Función para canviar la paginación de la tabla
  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value))
  }

  // Función para hacer funcionar el filtro de elementos => por inspeccion
  const handleChangeInspection = (value) => {
    const firstDate = new Date(1900, 1, 1)
    const lastDate = new Date(2900, 1, 1)
    const firstDate2 = filterInitialDate !== null && new Date(filterInitialDate)
    const lastDate2 = filterFinalDate !== null && new Date(filterFinalDate)
    const firstDate1 = filterInitialDate === null || filterInitialDate === undefined || filterInitialDate === "" ? firstDate.toISOString() : firstDate2.toISOString()
    const newData1 = filterFinalDate !== null && moment(lastDate2).add(1, 'days').format()
    const newData2 = new Date(newData1)
    const lastDate1 = filterFinalDate === null || filterFinalDate === undefined || filterFinalDate === "" ? lastDate.toISOString() : newData2.toISOString()

    if (value.length !== 0) {
      setFilterElements(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementId._id) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId)  
      })
      setInpectionsFiltered(inspectionsFilters)
    } else {
      let allElements = elements.map(element => element._id)
      setFilterElements(allElements)
      let inspectionFiltered = inspections.filter(element =>  {
        return allElements.includes(element.elementId._id) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
    console.log(filterInitialDate, filterFinalDate)
  }

  // Placeholder del filtro de inspecciones
  const placeholderFilterInspections = "Filtre inspeccions"

  // Array con los elementos del filtro de inspecciones
  const dataInspections = elements.map(element => {
    return {
      label: `${element.elementName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por empresa
  const handleChangeCompany = (value) => {
    const firstDate = new Date(1900, 1, 1)
    const lastDate = new Date(2900, 1, 1)
    const firstDate2 = filterInitialDate !== null && new Date(filterInitialDate)
    const lastDate2 = filterFinalDate !== null && new Date(filterFinalDate)
    const firstDate1 = filterInitialDate === null || filterInitialDate === undefined || filterInitialDate === "" ? firstDate.toISOString() : firstDate2.toISOString()
    const newData1 = filterFinalDate !== null && moment(lastDate2).add(1, 'days').format()
    const newData2 = new Date(newData1)
    const lastDate1 = filterFinalDate === null || filterFinalDate === undefined || filterFinalDate === "" ? lastDate.toISOString() : newData2.toISOString()
    if (value.length !== 0) {
      setFilterCompany(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementId.elementCompanyId) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId)  
      })
      setInpectionsFiltered(inspectionsFilters)
    } else {
      let allCompanies = companies.map(element => element._id)
      setFilterCompany(allCompanies)
      let inspectionFiltered = inspections.filter(element =>  {
        return  allCompanies.includes(element.elementId.elementCompanyId) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de empresa
  const placeholderFilterCompany = "Filtre empresa"

  // Array con los elementos del filtro de empresa
  const dataCompany = companies.map(element => {
    return {
      label: `${element.companyName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por Àrea
  const handleChangeHeadquarter = (value) => {
    const firstDate = new Date(1900, 1, 1)
    const lastDate = new Date(2900, 1, 1)
    const firstDate2 = filterInitialDate !== null && new Date(filterInitialDate)
    const lastDate2 = filterFinalDate !== null && new Date(filterFinalDate)
    const firstDate1 = filterInitialDate === null || filterInitialDate === undefined || filterInitialDate === "" ? firstDate.toISOString() : firstDate2.toISOString()
    const newData1 = filterFinalDate !== null && moment(lastDate2).add(1, 'days').format()
    const newData2 = new Date(newData1)
    const lastDate1 = filterFinalDate === null || filterFinalDate === undefined || filterFinalDate === "" ? lastDate.toISOString() : newData2.toISOString()
    if (value.length !== 0) {
      setFilterHeadquarters(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementId.elementAreaId) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id)
      })
      setInpectionsFiltered(inspectionsFilters)
    } else {
      let allHeadquarters = headquarters.map(element => element._id)
      setFilterHeadquarters(allHeadquarters)
      let inspectionFiltered = inspections.filter(element =>  {
        return  allHeadquarters.includes(element.elementId.elementAreaId) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de areas
  const placeholderFilterHeadquarters = "Filtre centre"

  // Array con los elementos del filtro de areas
  const dataHeadquarters = headquarters.map(element => {
    return {
      label: `${element.areaName}`,
      value: element._id
    }
  })
  
  // Función para hacer funcionar el filtro de elementos => por usuario planificado
  const handleChangeUser = (value) => {
    const firstDate = new Date(1900, 1, 1)
    const lastDate = new Date(2900, 1, 1)
    const firstDate2 = filterInitialDate !== null && new Date(filterInitialDate)
    const lastDate2 = filterFinalDate !== null && new Date(filterFinalDate)
    const firstDate1 = filterInitialDate === null || filterInitialDate === undefined || filterInitialDate === "" ? firstDate.toISOString() : firstDate2.toISOString()
    const newData1 = filterFinalDate !== null && moment(lastDate2).add(1, 'days').format()
    const newData2 = new Date(newData1)
    const lastDate1 = filterFinalDate === null || filterFinalDate === undefined || filterFinalDate === "" ? lastDate.toISOString() : newData2.toISOString()
    if (value.length !== 0) {
      setFilterUsers(value)
      let inspectionsFilters = inspections.filter(element => {
        return value.includes(element.elementUserPlanningId._id) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation)
      })
      setInpectionsFiltered(inspectionsFilters)
    } else {
      let allUsers = users.map(element => element._id)
      setFilterUsers(allUsers)
      let inspectionFiltered = inspections.filter(element =>  {
        return  allUsers.includes(element.elementUserPlanningId._id) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de usuario planificado
  const placeholderFilterUser = "Filtre usuari planificat"

  // Array con los elementos del filtro de usuario planificado
  const dataUser = users.map(element => {
    return {
      label: `${element.userName} ${element.userLastName1} ${element.userLastName2}`,
      value: element._id
    }
  })

  const valueUser = LocalStorage.getItem("id")

  // Función para hacer funcionar el filtro de elementos => por estado
  const handleChangeValidate = (value) => {
    const firstDate = new Date(1900, 1, 1)
    const lastDate = new Date(2900, 1, 1)
    const firstDate2 = filterInitialDate !== null && new Date(filterInitialDate)
    const lastDate2 = filterFinalDate !== null && new Date(filterFinalDate)
    const firstDate1 = filterInitialDate === null || filterInitialDate === undefined || filterInitialDate === "" ? firstDate.toISOString() : firstDate2.toISOString()
    const newData1 = filterFinalDate !== null && moment(lastDate2).add(1, 'days').format()
    const newData2 = new Date(newData1)
    const lastDate1 = filterFinalDate === null || filterFinalDate === undefined || filterFinalDate === "" ? lastDate.toISOString() : newData2.toISOString()
    if (value.length !== 0) {
      setFilterValidate(value)
      let elementsFiltereds = inspections.filter(element => {
        return value.includes(element.elementPlanningValidation) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive)
      })    
      setInpectionsFiltered(elementsFiltereds)
    } else {
      let allValidations = [ true, false ]
      setFilterValidate(allValidations)
      let inspectionFiltered = inspections.filter(element =>  {
        return allValidations.includes(element.elementPlanningValidation) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id) && filterActive.includes(element.elementPlanningActive)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de estado
  const placeholderFilterValidate = "Filtre estat"

  // Array con los elementos del filtro de estado
  const dataValidate = [
    {
      label: "Pendent validar",
      value: false
    },
    {
      label: "Validada",
      value: true
    },
  ]
  
  // Función para hacer funcionar el filtro de elementos => por estado realizado
  const handleChangeActive = (value) => {
    const firstDate = new Date(1900, 1, 1)
    const lastDate = new Date(2900, 1, 1)
    const firstDate2 = filterInitialDate !== null && new Date(filterInitialDate)
    const lastDate2 = filterFinalDate !== null && new Date(filterFinalDate)
    const firstDate1 = filterInitialDate === null || filterInitialDate === undefined || filterInitialDate === "" ? firstDate.toISOString() : firstDate2.toISOString()
    const newData1 = filterFinalDate !== null && moment(lastDate2).add(1, 'days').format()
    const newData2 = new Date(newData1)
    const lastDate1 = filterFinalDate === null || filterFinalDate === undefined || filterFinalDate === "" ? lastDate.toISOString() : newData2.toISOString()
    if (value.length !== 0) {
      setFilterActive(value)
      let elementsFiltereds = inspections.filter(element => {
        return value.includes(element.elementPlanningActive) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id)
      })    
      setInpectionsFiltered(elementsFiltereds)
    } else {
      let allStates = [ true, false ]
      setFilterActive(allStates)
      let inspectionFiltered = inspections.filter(element =>  {
        return allStates.includes(element.elementPlanningActive) && element.elementPlanningDate >= firstDate1 && element.elementPlanningDate <= lastDate1 && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
  }

  // Placeholder del filtro de estado realizado
  const placeholderFilterActive = "Filtre realitzat"

  // Array con los elementos del filtro de estado realizado
  const dataActive = [
    {
      label: "Pendent fer",
      value: false
    },
    {
      label: "Feta",
      value: true
    },
  ]
  
  // Función para hacer funcionar el filtro de elementos => por data de inicio
  const handleChangeInitialDate = (event) => {
    const firstDate = new Date(1900, 1, 1)
    const newData1 = new Date(event.target.value)
    const newData = event.target.value === ""  ? firstDate.toISOString() : newData1.toISOString()
    if (filterFinalDate === null || filterFinalDate === undefined  || filterFinalDate === "") {
      let elementsFiltereds = inspections.filter(element => {
        return element.elementPlanningDate >= newData && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id)
      })    
      setInpectionsFiltered(elementsFiltereds)
    } else {
      const finalData = new Date(filterFinalDate)
      const newFinalData = finalData.toISOString()
      let inspectionFiltered = inspections.filter(element =>  {
        return element.elementPlanningDate >= newData && element.elementPlanningDate <= newFinalData && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
    setFilterInitialDate(event.target.value)
  }

  // Función para hacer funcionar el filtro de elementos => por data final
  const handleChangeFinalDate = (event) => {
    const lastDate = new Date(2900, 1, 1)
    const newData1 = moment(event.target.value).add(1, 'days').format()
    const newData2 = new Date(newData1)
    const newData = event.target.value === ""  ? lastDate.toISOString() : newData2.toISOString()
    if (filterInitialDate === null || filterInitialDate === undefined || filterInitialDate === "") {
      let elementsFiltereds = inspections.filter(element => {
        return element.elementPlanningDate <= newData && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id)
      })    
      setInpectionsFiltered(elementsFiltereds)
    } else {
      const initialData = new Date(filterInitialDate)
      const newInitialData = initialData.toISOString()
      let inspectionFiltered = inspections.filter(element =>  {
        return element.elementPlanningDate <= newData && element.elementPlanningDate >= newInitialData && filterActive.includes(element.elementPlanningActive) && filterValidate.includes(element.elementPlanningValidation) && filterUsers.includes(element.elementUserPlanningId._id) && filterHeadquarters.includes(element.elementId.elementAreaId) && filterCompany.includes(element.elementId.elementCompanyId) && filterElements.includes(element.elementId._id)
      }) 
      setInpectionsFiltered(inspectionFiltered)
    }
    setFilterFinalDate(event.target.value)
  }

  const handelSelectedInspections = (position) => {
    const updatedCheckedState = isChecked.map((item, index) =>
      index === position ? !item : item
    )
    setIsChecked(updatedCheckedState)
  }
  
  const handelSelectedAllInspections = (event) => {
    const newValues = []
    if (event.target.checked === true) {
      for (let index = 0; index < isChecked.length; index++) {
        newValues[index] = true
      }
    } else {
      for (let index = 0; index < isChecked.length; index++) {
        newValues[index] = false
      }
    }
    setIsChecked(newValues)
  }

  return (
    <div className={styles.container}>
        <div className={styles.main}>
          {LocalStorage.getItem("userProfile") === "Administrador" && <div className={styles.filtereds}>
            <div className={styles.multiselector}>
              <Filter
                data={dataInspections} 
                handleChange={handleChangeInspection}
                placeholderFilter={placeholderFilterInspections}
              />
            </div>
            <div className={styles.multiselector}>
              <Filter
                data={dataCompany} 
                handleChange={handleChangeCompany}
                placeholderFilter={placeholderFilterCompany}
              />
            </div>
            <div className={styles.multiselector}>
              <Filter
                data={dataHeadquarters} 
                handleChange={handleChangeHeadquarter}
                placeholderFilter={placeholderFilterHeadquarters}
              />
            </div>
            <div className={styles.multiselector}>
              <Filter
                data={dataUser} 
                handleChange={handleChangeUser}
                placeholderFilter={placeholderFilterUser}
                value={valueUser}
              />
            </div>
            <div className={styles.multiselector}>
              <Filter
                data={dataValidate} 
                handleChange={handleChangeValidate}
                placeholderFilter={placeholderFilterValidate}
              />
            </div>
            <div className={styles.multiselector}>
              <Filter
                data={dataActive} 
                handleChange={handleChangeActive}
                placeholderFilter={placeholderFilterActive}
                value={false}
              />
            </div>
            <div className={styles.multiselector}>
              <input 
                type='date' className={styles.datepicker} 
                onChange={(e) => handleChangeInitialDate(e)} 
                value={filterInitialDate}
                max={filterFinalDate}
              >
              </input>
            </div>
            <div className={styles.multiselector}>
              <input 
                type='date' 
                className={styles.datepicker} 
                onChange={(e) => handleChangeFinalDate(e)} 
                value={filterFinalDate}
                min={filterInitialDate}
              >
              </input>
            </div>
          </div>
          }
          <div className={styles.menubuttons}>
            <div className={styles.pagination}>
              <button onClick={() => handlePagination("prev")}><i className="fa-solid fa-backward"></i></button>
              <button onClick={() => handlePagination("next")}><i className="fa-solid fa-forward"></i></button>
              <p>
                <span>{pageSize * (currentPage - 1)}</span> a <span>{currentDataDisplayed && currentDataDisplayed.length+(currentPage - 1) * pageSize}</span> de <span>{inpectionsFiltered?.length}</span> resultats
              </p>
              <div className={styles.view}>
                <span>Mostrar </span>
                <select onChange={handleChangePageSize}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
                <span> resultats</span>
              </div>
            </div>
            {
              LocalStorage.getItem("userProfile") === "Administrador" &&
                <div className={styles.button}>
                  <Button path="/inspeccions/inspection-calendar" icon={"fa-calendar-days"}/>
                  <Button handleOnClick={handleValidateMultiple} icon={"fa-square-check"}/>
                  <Button handleOnClick={handleDesactiveMultiple} icon={"fa-trash"}/>
                  <Button handleOnClick={handlePrint} icon={"fa-print"}/>
                  <Button path="/inspeccions/new-inspeccio" icon={"fa-circle-plus"}/>
                </div>
            }
          </div>
        </div>
        <div className={styles.listElements} ref={componentRef}>
          <div className={`${styles.table} ${styles.ver3}`}>
            <table data-vertable="ver3">
              <thead>
                <tr className={`${styles.rows} ${styles.head}`}>
                  {
                    titles.map((element, index) =>{
                      return index === 0 ?
                        <th className={styles.columns} key={index}><input type='checkbox' onChange={handelSelectedAllInspections}></input></th>  :
                        <th className={styles.columns} key={index}>{element}</th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {currentDataDisplayed
                  ? currentDataDisplayed.map((element, index) => {
                    let url = '/inspeccions/' + element._id
                    let area = headquarters.filter(area => area._id === element.elementId.elementAreaId)
                    let checkedItem = isChecked[index]
                    return (
                      <tr className={styles.rows} id={element._id} key={element._id}>
                        <td className={styles.columns}>
                          <input type='checkbox' id={index} value={element._id} checked={checkedItem} onChange={() => handelSelectedInspections(index)}></input>
                        </td>
                        <td className={styles.columns}><Link to={url}>{element.elementId.elementName}</Link></td>
                        <td className={styles.columns}>{area[0] && area[0].areaName}</td>
                        <td className={styles.columns}>
                          {element.elementRegisters &&
                            element.elementRegisters.map(element => {
                              return <>{element.elementRegistersUpkeeps.upkeepName}<br></br></>
                            })
                          }
                        </td>
                        <td className={styles.columns}>
                          {element.elementRegisters &&
                            element.elementRegisters.map(element => {
                              return <>{element.elementValues}<br></br></>
                            })
                          }
                        </td>
                        <td className={styles.columns}>
                          {element.elementRegisters &&
                            element.elementRegisters.map((element, index) => {
                              if (element.elementRegistersUpkeeps.upkeepValue === "number" && element.elementValues !== "") {
                                if (element.elementRegistersUpkeeps.upkeepLimitType === "up") {
                                  return <>{parseFloat(element.elementValues) <= parseFloat(element.elementRegistersUpkeeps.upkeepLimitUp) ? <i key={index} className="fa-solid fa-thumbs-up" id={styles.ok}></i> : <i key={index} className="fa-solid fa-thumbs-down" id={styles.nok}></i>}<br></br></>
                                } else if (element.elementRegistersUpkeeps.upkeepLimitType === "down") {
                                  return <>{parseFloat(element.elementValues) >= parseFloat(element.elementRegistersUpkeeps.upkeepLimitDown) ? <i key={index} className="fa-solid fa-thumbs-up" id={styles.ok}></i> : <i key={index} className="fa-solid fa-thumbs-down" id={styles.nok}></i>}<br></br></>
                                } else {
                                  return <>{parseFloat(element.elementValues) <= parseFloat(element.elementRegistersUpkeeps.upkeepLimitUp) && parseFloat(element.elementValues) >= parseFloat(element.elementRegistersUpkeeps.upkeepLimitDown) ? <i key={index} className="fa-solid fa-thumbs-up" id={styles.ok}></i> : <i key={index} className="fa-solid fa-thumbs-down" id={styles.nok}></i>}<br></br></>
                                }
                              } else if (element.elementRegistersUpkeeps.upkeepValue === "boolean") {
                                return <>{element.elementValues === element.elementRegistersUpkeeps.upkeepLimit ?  <i key={index} className="fa-solid fa-thumbs-up" id={styles.ok}></i> : <i key={index} className="fa-solid fa-thumbs-down" id={styles.nok}></i>}<br></br></>
                              } else if (element.elementRegistersUpkeeps.upkeepValue === "string") {
                                return <>{element.elementValues === "Correcte" ?  <i key={index} className="fa-solid fa-thumbs-up" id={styles.ok}></i> : <i key={index} className="fa-solid fa-thumbs-down" id={styles.nok}></i>}<br></br></>
                              } else {
                                return <></>
                              }
                            })
                          }
                        </td>
                        <td className={styles.columns}>{new Date(element.elementPlanningDate).toLocaleDateString()}</td>
                        <td className={styles.columns}>{element.elementDoDate && new Date(element.elementDoDate).toLocaleDateString()}</td>
                        <td className={styles.columns}>
                          P: {element.elementUserPlanningId.userName} {element.elementUserPlanningId.userLastName1} <br></br>
                          R: {element.elementUserDoId && element.elementUserDoId.userName} {element.elementUserDoId && element.elementUserDoId.userLastName1}
                        </td>
                        <td className={styles.columns}>{element.elementActions && <Link to={url}><i className="fa-solid fa-triangle-exclamation" id={styles.action}></i></Link>}</td>
                        <td className={styles.columns}>{!element.elementDoDate ? "Pendent fer" : !element.elementPlanningValidation ? "Pendent validar" : "Tancada"}</td>
                        <td className={styles.columns}>
                          {
                            LocalStorage.getItem("userProfile") === "Administrador" && <>
                                {element.elementPlanningValidation === false && element.elementDoDate ? <i className="fa-solid fa-square-check" onClick={handleValidate}></i> : <div></div>}
                                <Link to={url}><i className="fa-solid fa-pen-to-square"></i></Link>
                                <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
                            </>
                          }
                        </td>
                      </tr>  
                    )
                  }) : null
                }
              </tbody>
            </table>
          </div>
        </div>
    </div>
  )
}

export default Inspections
