export const catalan_calendar = {
    name: "catalan_calendar",
    months: [
      ["gener", "gen"],
      ["febrer", "febr"],
      ["març", "març"],
      ["abril", "abr"],
      ["maig", "maig"],
      ["juny", "juny"],
      ["juliol", "jul"],
      ["agost", "ag"],
      ["setembre", "set"],
      ["octubre", "oct"],
      ["novembre", "nov"],
      ["desembre", "des"],
    ],
    weekDays: [
      ["dissabte", "ds"],
      ["diumenge", "dg"],
      ["dilluns", "dl"],
      ["dimarts", "dm"],
      ["dimecres", "dc"],
      ["dijous", "dj"],
      ["divendres", "dv"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["AM", "am"],
      ["PM", "pm"],
    ],
}

