import { LocalStorage } from '../localStorage/LocalStorage.services'

const getAllElements = async () => {

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/elements`,
            {
                headers: {
                    'auth-token': LocalStorage.getItem("token")
                },
            }
        )
        if (response.ok) {
            const data = await response.json()
            
            const dataNoDeleted = data.filter(element => {
                return element.elementDeleted === false
            })
            return  dataNoDeleted
        } else {
            console.log('error')
        }
    } catch (error) {
        return {message: error.message || "Something went wrong"}
    }
    
}
    
export default getAllElements;