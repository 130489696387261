import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import Modal from '../../components/Modal/Modal'
import getCompanyById from '../../services/companies/getCompanyById'
import postCompany from '../../services/companies/postCompany'
import putCompany from '../../services/companies/putCompany'

import styles from './NewCompany.module.css'

const NewCompany = () => {

    const { id } = useParams()
    const [ newCompany, setNewCompany ] = useState({})
    const [ active, setActive ] = useState(true)
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)

    useEffect(() => {
        if (id !== undefined) {
            getCompanyById(id)
            .then(company => {
                setNewCompany(company)
                setActive(company.companyActive)
            })
        }
    },[id])

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        formData.append('companyActive', event.target.companyActive.checked)
        const formJson = Object.fromEntries(formData.entries())
        if (id !== undefined) {
            putCompany(formJson, id) 
            .then(newCompany => {
                setNewCompany(newCompany);
                setDisable(false)
                setTimeout(() => {
                    setDisable(true)
                },3000)
            })
        } else {
            postCompany(formJson) 
            .then(newCompany => {
                setNewCompany(newCompany);
                setDisable(false)
                setTimeout(() => {
                    setDisable(true)
                },3000)
            })
        }
    }

    const handleChange = (event) => {
        if (event.target.id === "companyActive") {
            setActive(event.target.checked)
        } else {
            setNewCompany(event.target.value);
        }
    }
    
    return (
        <div className={styles.container}>
            <h2>{id ? "Editar element" : "Alta nou element"}</h2>
            <div className={disable && styles.disable}>
                <Modal error={error} id={id} text={id ? "Empresa modificada correctament" : "Empresa creada correctament"}></Modal>
            </div>
            <form onSubmit={handleSubmit}>
                <label for="companyName">Empresa</label>
                <input type='text' onChange={handleChange} name="companyName" value={newCompany.companyName}></input>
                <label for="companyCIF">CIF</label>
                <input type='text' onChange={handleChange} name="companyCIF" value={newCompany.companyCIF}></input>
                <label for="companyActive">Actiu</label>
                <input type='checkbox' onChange={handleChange} name="companyActive" id="companyActive" checked={active} className={styles.checking}/>
                <div className={styles.btn_container}>
                    <button className={styles.btn_add} type='submit'>{id ? <i className="fa-regular fa-floppy-disk"></i> : <i className="fa-solid fa-circle-plus"></i>}{id ? "Guardar" : "Crear"}</button>
                </div>
            </form>
        </div>
    )
}

export default NewCompany