import { useEffect, useState, useRef } from 'react'
import { useParams } from "react-router-dom"
import { Chart } from "react-google-charts"
import { useReactToPrint } from 'react-to-print'
import Button from '../../components/UI/Button'
import getElementById from '../../services/elements/getElementById'
import getUpkeeps from '../../services/upkeeps/getAllUpkeeps'
import getOperations from '../../services/operations/operations'

import styles from './GraphicsElement.module.css'

const GraphicsElement = () => {

    const componentRef = useRef([])

    const { id } = useParams()
    const [ element, setElement ] = useState({})
    const [ upkeeps, setUpkeeps ] = useState([])
    const [ filters, setFilters ] = useState([])

    useEffect(() => {
        getElementById(id)
        .then(element => {
            setElement(element)
        })
        getOperations()
        .then(data => {
            const dataFilter = data.arrayGroup.filter(element => {
                return element.elementId === id
            })
            setFilters(dataFilter[0].elementValuesByUpkeep)
        })
        getUpkeeps()
        .then(upkeeps => {
            setUpkeeps(upkeeps)
        })
    },[id])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Gràfic",
        onafterprint: () => alert('Print success')
    })

    return (
        <div className={styles.container} ref={componentRef}>
            <h2 className={styles.title}>{element.elementName}</h2>
            {
                upkeeps && upkeeps.map((element, index) => {
                    const options = {
                        chart: {
                            title: element.upkeepName,
                            subtitle: element.upkeepTypeId.upkeepTypeUnits,
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }

                    const data2 = ["Inspecció", element.upkeepName, "Limit superior", "Limit inferior"]
                    
                    const data1 = filters.filter(elem => {
                        return elem.upkeep === element._id
                    })

                    const data = data1.map((elements, index) => {
                        return elements.values.map((elementValues, index) => {
                            return [index, elementValues === "" ? 0 : parseInt(elementValues), element.upkeepLimitUp !== "" && parseInt(element.upkeepLimitUp), element.upkeepLimitDown !== "" && parseInt(element.upkeepLimitDown),]
                        })
                    })
                    
                    data.map((element, index) => {
                        return element.unshift(data2)
                    }) 
                    
                    return data.length > 0 && <div className={styles.graph} >
                        <Chart
                            chartType="Line"
                            width="100%"
                            height="400px"
                            data={data[0]}
                            options={options}
                            legend={{fontSize: 8}}
                        />
                    </div>
                })
            }
            <div className={styles.button}>
                <Button handleOnClick={handlePrint} icon={"fa-print"}/>
            </div>
        </div>
    )
}

export default GraphicsElement