import { Link } from 'react-router-dom'
import styles from './Button.module.css'

const Button = (props) => {

    return (
        <Link className={styles.button} to={props.path} onClick={props.handleOnClick}>
            <i className={`fa-solid ${props.icon}`}></i>
        </Link>
    )
    
}

export default Button