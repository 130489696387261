import { useState } from 'react'
import { validateEmail, validatePassword, validatePasswordLength } from '../../utils/validate'
import { LocalStorage } from "../../services/localStorage/LocalStorage.services"
import Login from './Login'
import Modal from '../../components/Modal/Modal'

import styles from './LoginPage.module.css'

const LoginPage = (props) => {

    const [ pending, setPending ] = useState(false)
    const [ visible, setVisible ] = useState(false)
    const [ userData, setUserData ] = useState({
        loggedIn: false,
        userUser: LocalStorage.getItem("userUser") || "",
        userPassword: LocalStorage.getItem("userPassword") || "",
        loginHeader: '',
        loginMessage: '',
    })

    const handleVisibility = async (loginData) => {
        if (loginData && validateEmail(loginData.userUser) && validatePassword(loginData.userPassword) && validatePasswordLength(loginData.userPassword)) {
            setPending(true)
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, 
                    {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'auth-token': LocalStorage.getItem("token")
                        },
                        body: JSON.stringify({
                            userUser: loginData.userUser,
                            userPassword: loginData.userPassword,
                        })
                    }
                )
                const data = await response.json()
                if (response.ok) {
                    LocalStorage.setItem("userUser", loginData.userUser)
                    LocalStorage.setItem("token", data.data.token)
                    LocalStorage.setItem("refreshToken", data.data.refreshToken)
                    LocalStorage.setItem("userProfile", data.data.userProfile)
                    LocalStorage.setItem("id", data.data.id)
                    setUserData({
                        loggedIn: true,
                        userUser: loginData.userUser,
                        userPassword: '*********',
                        loginHeader: "Loggin correcte",
                        loginMessage: "Has estat loguejat correctament.",
                    })
                    setVisible(true)
                    setTimeout(()=> {
                        props.setIsLogged(true)
                    },3000)
                }
            } catch (error) {
                setUserData({
                    loggedIn: false,
                    userUser: loginData.userUser,
                    userPassword: "*********" ,
                    loginHeader: "Loggin incorrecte",
                    loginMessage: error.message,
                })
            }
            setVisible(true)
            setTimeout(()=> {
                setVisible(false)
            },3000)
        } else {
            setUserData({
                loggedIn: false,
                userUser: loginData.userUser === "" ? "Email requerit" : loginData.userUser,
                userPassword: loginData.userPassword === "" ? "Password requerit" : loginData.userPassword,
                loginHeader: "Loggin incorrecte",
                loginMessage: "Usuari o password incorrecte.",
            })
            setVisible(true)
            setTimeout(()=> {
                setVisible(false)
            },3000)
        }
    }
  
    return (
        <div>
            <Login onLogin={handleVisibility}></Login>
            <div className={!visible ? styles.disable : styles.visible} id={styles.modal}>
                <Modal error={userData.loggedIn === false ? userData.loginMessage : "" } text={userData.loginMessage}></Modal>
            </div>
        </div>
    )
}

export default LoginPage