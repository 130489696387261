import { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { LocalStorage } from '../../services/localStorage/LocalStorage.services'
import moment from 'moment'
import Modal from '../../components/Modal/Modal'
import ModalDuplicate from '../../components/ModalDuplicate/ModalDuplicate'
import getAllInspections from '../../services/inspections/getAllInspections'
import getAllElements from '../../services/elements/getAllElements'
import getAllUsers from '../../services/users/getAllUsers'
import putInspection from '../../services/inspections/putInspection'

import styles from './EditInspection.module.css'

const EditInspection = (props) => {
    
    let navigate = useNavigate()

    const { id } = useParams()
    const [ inspection, setInspection ] = useState({})
    const [ values, setValues ] = useState([])
    const [ elements, setElements ] = useState([])
    const [ users, setUsers ] = useState([])
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)
    const [ disableDuplicate, setDisableDuplicate ] = useState(true)

    
    useEffect(() => {
        getAllInspections()
        .then(inspection => inspection.filter(element => element._id === id))
        .then(inspection => {
            let inspectionFiltered = inspection[0]
            setInspection(inspectionFiltered) 
            let entriesValues = inspectionFiltered.elementRegisters.map(element => {
                return {
                    "id" : element.elementRegistersUpkeeps,
                    "data" : element.elementValues
                }
            })      
            setValues(entriesValues)
        })
        getAllElements()
        .then(elements => {
            setElements(elements)
        })
        getAllUsers()
        .then(users => {
            setUsers(users)
        })
    },[id])

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        event.target.elementDoDate.value === "" ? formData.append('elementDoDate', moment(new Date()).format('YYYY-MM-DD')) : formData.append('elementDoDate', event.target.elementDoDate.value)
        formData.append('elementUserDoId', event.target.elementUserDoId.value)
        formData.append('elementPlanningActive', true)
        const formJson = Object.fromEntries(formData.entries()) 
        const arrayValues = values.map(element => {
            return {
                "elementRegistersUpkeeps": element.id,
                "elementValues": element.data
            }
        })
        formJson.elementRegisters = arrayValues
        putInspection(formJson, id) 
        .then(newInspection => {
            setInspection(newInspection);
            setDisable(false)
            setTimeout(() => {
                setDisable(true)
                navigate("/inspeccions", { replace: true })
            },3000)
        })
    }

    
    const handleChange = (event, id) => {
        if (event.target.name === "elementValues") {
            const newEntry = values.map((item) => {
                return item.id === id ? { ...item, data: event.target.value } : item 
            })
            setValues(newEntry)
        } else {
            const { name, value } = event.target
            setInspection ( ( prevFormData ) => ({ 
              ...prevFormData, 
              [name]: value, 
            })); 
        }
    }

    return (
        
        <div className={ `${styles.container} ${disableDuplicate === false && styles.container_transparent}`}>
            <div className={disable && styles.disable}>
                <Modal error={error} id={props.id} text={"Inspecció modificada correctament"}></Modal>
            </div>
            <div className={disableDuplicate && styles.disable}>
                <ModalDuplicate inspection={inspection} setDisableDuplicate={setDisableDuplicate}></ModalDuplicate>
            </div>
            <h2>Editar inspecció</h2>
            <form onSubmit={handleSubmit}>
                <label for="elementId">Element</label>
                <select name="elementId" value={inspection.elementId ? inspection.elementId._id : inspection.elementId} disabled>
                    {
                        elements.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.elementName} {element.userLastName1} {element.userLastName2}</option>
                            )
                        })
                    }
                </select>
                <label for='elementPlanningDate'>Data planificada</label>
                <input type='date' name="elementPlanningDate" id="elementPlanningDate" value={moment(new Date(inspection.elementPlanningDate)).format('YYYY-MM-DD')} disabled/>
                <label for="elementUserPlanningId">Usuari Planificat</label>
                <select name="elementUserPlanningId" id="elementUserPlanningId" value={inspection.elementUserPlanningId ? inspection.elementUserPlanningId._id : inspection.elementId} disabled>
                    {
                        users.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.userName} {element.userLastName1} {element.userLastName2}</option>
                            )
                        })
                    }
                </select>
                <label for="elementDoDate">Data realitzada</label>
                <input type='date' onChange={handleChange} name="elementDoDate" value={inspection.elementDoDate ? moment(new Date(inspection.elementDoDate)).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')} disabled={inspection.elementPlanningValidation || LocalStorage.getItem("userProfile") !== "Administrador" ? true : false}/>
                <label for="elementUserDoId">Usuari Realitzat</label>
                <select onChange={handleChange} name="elementUserDoId" id="elementUserDoId" disabled={inspection.elementPlanningValidation || LocalStorage.getItem("userProfile") !== "Administrador" ? true : false}>
                    {
                        users.map( element => {
                            return (
                                <option key={element._id} selected={element._id === LocalStorage.getItem("id") && "selected"} value={element._id}>{element.userName} {element.userLastName1} {element.userLastName2}</option>
                            )
                        })
                    }
                </select>
                {values &&
                    values.map( (element, index) => {
                        return (<>
                            <label for="elementRegistersUpkeeps">Manteniment</label>
                            <select name="elementRegistersUpkeeps" id="elementRegistersUpkeeps" value={element.id} disabled>
                                <option value={element._id}>{element.id.upkeepName}</option>
                            </select>
                            <label for="elementValues">Valors</label>
                            <input key={element.id} type={element.id.upkeepValue} onChange={(event) => handleChange(event, element.id)} name="elementValues" value={element.data} disabled={inspection.elementPlanningValidation ? true : false}></input>
                        </>
                        )
                    })
                }
                <label for='elementActions'>Accions</label>
                <textarea onChange={handleChange} name="elementActions" id="elementActions" value={inspection.elementActions} disabled={inspection.elementPlanningValidation ? true : false}/>
                {
                    inspection.elementPlanningValidation ?
                        <div className={styles.btn_container_alert}>                    
                            <div className={styles.btn_alert}>La inspecció està feta, tancada i validada i no es permet cap modificació de les dades</div>
                        </div>
                        :
                        <div className={styles.btn_normal}>
                            <div className={styles.btn_container_button}>                    
                                <button className={styles.btn_add} type='submit' disabled={inspection.elementPlanningValidation ? true : false}><i className="fa-regular fa-floppy-disk"></i>Guardar</button>
                            </div>
                            {
                                !inspection.elementDoDate && <div className={styles.btn_container_button}>                    
                                    <button className={styles.btn_add} type='button' onClick={() => setDisableDuplicate(false)} disabled={inspection.elementPlanningValidation ? true : false}><i className="fa-regular fa-copy"></i>Duplicar</button>
                                </div>
                            }
                        </div>
                }
            </form>
        </div>
    )
}

export default EditInspection